jQuery(document).ready(function ($) {

    // Sidebar mobile toggle
    $(".category-panel").find(".panel-title").on("click", function(ev) {
        ev.preventDefault();
        if($(window).width() <= 1024) {
            $(".category-panel").find("ul.categories").slideToggle();
            $(this).find(".arrow").toggleClass("open");
        }
    });

    // Tile preview toggle
    $("a.toggle-preview").on("click", function(ev) {
        ev.preventDefault();

        var id = this.dataset.content;
        $("#"+id).slideDown();
        // Scroll down
        $('html, body').animate({
            scrollTop: $("#"+id).offset().top - 100
        }, 1000);
    });

    // Load offers request
    $("#main-offers").on("click", "a.cat", function (ev) {
        ev.preventDefault();

        // Save the element
        var source = this.dataset.request;
        var category = this.dataset.category;
        var loader = $(".requestLoader");
        var initialContent = $("div.welcome");

        // Make sure its not already processing
        if ($(this).hasClass('loading') === false) {
            // Create data object
            var data = {
                action: 'load-offers',
                category: category
            };

            // Hide the main content if visible
            initialContent.hide();

            // Add the loading class
            $(this).addClass('loading');
            loader.addClass('loading');

            // Send the request
            $.ajax({
                url: source,
                data: data,
                dataType: 'json',
                type: 'POST',
                success: function (response) {
                    // Check the response
                    if (response.error) {
                        // There was an error
                        addNotification(response.response, 'error');
                    }
                    else {
                        // Get the response
                        response = response.response;

                        // Check if there's a handler set
                        if (response.hasOwnProperty('handler')) {
                            // Get the handler
                            var handler = response.handler.charAt(0).toUpperCase() + response.handler.substr(1) + 'Handler';

                            // Check if the handler exists
                            if (typeof(window[handler]) === 'function') {
                                // Create the handler
                                new window[handler](jQuery, this, response);
                            }
                        }
                    }

                    // Remove the loading class
                    $(this).removeClass('loading');
                    loader.removeClass('loading');

                }.bind(this),
                error: function () {
                    // Remove the loading class
                    $(this).removeClass('loading');
                    loader.removeClass('loading');
                }.bind(this)
            });
        }
    });
});