jQuery(document).ready(function($){


    // Get the ajax search Form
    var form = $('.ajax-search').find(".form");

    /**
     * Form pagination
     */
    $('body').on('click', '.form-pagination-button', function(){
        // Check the class
        if( $(this).hasClass('loading') === false ){

            // Get the form variables
            var form_data = form.serializeArray();

            // Create data object
            var data = {};
            $.each(form_data, function() {
                if (data[this.name] !== undefined) {
                    if (!data[this.name].push) {
                        data[this.name] = [data[this.name]];
                    }
                    data[this.name].push(this.value || '');
                } else {
                    data[this.name] = this.value || '';
                }
            });

            // Get the data
            var data = jQuery.extend(data, $(this).data());

            //Check if there's a source set
            if( data.hasOwnProperty('source') === false ) {
                // Not found
                throw 'No source for pagination';
            }

            // Get the source
            var source = data.source;

            // Remove the source
            delete data.source;

            // Check the page of the data
            if( data.hasOwnProperty('page') === false || $(this).data('page') == 0 ){
                // Add the page to the data
                data.page = 1;

                // Update the page
                $(this).data('page', 1);
            }

            // Add the ajax and pagination variable
            data._ajax = 1;
            data._pagination = 1;

            // Remove the error class
            $(this).removeClass('error');

            // Add the loading class
            $(this).addClass('loading');

            // Send the request
            $.ajax({
                url      : source,
                data     : data,
                dataType : 'json',
                type     : 'POST',
                success  : function(response){
                    // Check the response
                    if( response.error ){
                        // There was an error
                        $(this).addClass('error');

                        // Check if there's a results text
                        if( $(this).find('.results-text').length === 0 ){
                            // Add a results text
                            $(this).append('<p class="results-text"></p>');
                        }

                        // Add the response
                        $(this).find('.results-text').text(response.response);
                    }
                    else{
                        // Get the response
                        response = response.response;

                        // Check if there's a handler set
                        if( response.hasOwnProperty('handler') ){
                            // Get the handler
                            var handler = response.handler.charAt(0).toUpperCase() + response.handler.substr(1) + 'Handler';

                            // Check if the handler exists
                            if( typeof(window[handler]) === 'function' ){
                                // Create the handler
                                new window[handler](jQuery, this, response);
                            }
                        }
                        else{
                            // Use the default handler
                            new Handler(jQuery, this, response);
                        }
                    }

                    // Remove the loading class
                    $(this).removeClass('loading');

                }.bind(this),
                error    : function(){
                    // Remove the loading class
                    $(this).removeClass('loading');

                    // There was an error
                    $(this).addClass('error');

                    // Check if there's a results text
                    if( $(this).find('.results-text').length === 0 ){
                        // Add a results text
                        $(this).append('<p class="results-text"></p>');
                    }

                    // Add the response
                    $(this).find('.results-text').text('Could not send the request. Please try again.');
                }.bind(this)
            });
        }
    });

    /**
     * Request handler
     */
    $("body").on("click", ".ajax-request", function(event){

        // Get the action
        var url = this.dataset.request;
        var action = this.dataset.action;
        var value = this.dataset.value;
        var hasConfirm = this.dataset.confirm;
        if( hasConfirm ) {
            if( !confirm(hasConfirm) ) {
                event.preventDefault();
                return false;
            }
        }

        // Check if active
        if( $(this).hasClass('active') ){
            // Prevent the default
            event.preventDefault();
            return false;
        }

        // Set as active
        $(this).addClass('active');

        // Save the element
        var element = this;

        /// Build data
        var data = {
            action    : action,
            value     : value
        };

        // Send the ajax request
        $.ajax({
            url         : url,
            data        : data,
            method      : 'POST',
            dataType    : 'json',
            success     : function(response){

                // Check the response
                if( response.error ){
                    // Add a notification with the response
                    addNotification(response.response, 'error', 3000);
                } else{

                    // Get the response
                    response = response.response;

                    // Check if there's a handler set
                    if( response.hasOwnProperty('handler') ){
                        // Get the handler
                        var handler = response.handler.charAt(0).toUpperCase() + response.handler.substr(1) + 'Handler';

                        // Check if the handler exists
                        if( typeof(window[handler]) === 'function' ){
                            // Create the handler
                            new window[handler](jQuery, this, response);
                        }
                    }
                    else{
                        // Use the default handler
                        new Handler(jQuery, this, response);
                    }

                }

                // Remove the active class
                $(element).removeClass('active');

            }.bind(this),
            error: function(xhr){
                // Show a notification
                addNotification('There was an error sending the request', 'error');

                // Remove the active class
                $(element).removeClass('active');
            }
        });

        // Prevent the default
        event.preventDefault();
    });

    /**
     * Ajax search handler
     */
    $(".ajax-search").on("click", "button.search-button", function(event){
        // Check if the button is active
        if( $(this).hasClass('active') ){
            // Prevent the default
            event.preventDefault();
            return false;
        }

        // Get the form
        var form = $(this).closest('.form');

        // Remove any error class
        form.removeClass('error');

        // Set the button as active
        $(this).addClass('active');

        // Save the an reference to the button
        var button = this;

        // Get the wrapper container if any
        var resultsContainer = this.dataset.wrapper;

        // Passed validation or not
        var passed = true;

        // Get the fields in the form
        var fields = form.find('.input');
        var i = 0;

        for( var length = fields.length; i < length; i++ ){
            // Get the parent of the input
            var parent = $(fields[i]).closest("div.form-field");
            try{

                // Validate the input
                validateInput.call(fields[i]);

                // Validate passed
                parent.removeClass('error');
                parent.addClass('valid');

            } catch( e ){

                // Set the error
                parent.find('.tooltip').text(e);
                parent.removeClass('valid');
                parent.addClass('error');

                // Failed validation
                passed = false;

            }
        }

        // Check if the validation passed
        if( passed === false ){
            // Validation failed
            // Caught an error
            $(this).removeClass('active');

            // Prevent the default
            event.preventDefault();
            return false;
        }

        // Serialize the data
        var data = '_ajax=&' + form.serialize();

        // Get the action of the form
        var action = form.attr('action');

        // Send the ajax request
        $.ajax({
            url         : action,
            data        : data,
            method      : 'POST',
            dataType    : 'json',
            success     : function(response){
                // Get the response container
                var container = form.find('div.form-response');

                // Empty the container, and remove the classes
                container.empty();
                container.removeClass('success error');

                // Check the response
                if( response.error ){
                    // Add the error class to the form
                    form.addClass('error');

                    // Check the container
                    if( container.length > 0 ){
                        // There was an error submitting the form
                        // Add the error class
                        container.addClass('error');

                        // Add the response
                        container.append(response.response);
                    }
                    else{
                        // Add a notification with the response
                        addNotification(response.response, 'error', 3000);
                    }
                }
                else{

                    // Get the response
                    response = response.response;

                    // Check if there's a handler set
                    if( response.hasOwnProperty('handler') ){
                        // Get the handler
                        var handler = response.handler.charAt(0).toUpperCase() + response.handler.substr(1) + 'Handler';

                        // Check if the handler exists
                        if( typeof(window[handler]) === 'function' ){
                            // Create the handler
                            new window[handler](jQuery, this, response);
                        }
                    }
                    else{
                        // Use the default handler
                        new Handler(jQuery, this, response);
                    }

                    // Show load more
                    if( typeof response.more != "undefined" && response.more ){
                        $(".see-more-container").removeClass("hidden");
                        $(".see-more-container").find(".form-pagination-button").slideDown();
                    } else {
                        $(".see-more-container").addClass("hidden");
                        $(".see-more-container").find(".form-pagination-button").slideUp();

                    }

                    // Remove nothing found
                    if( typeof response.found == "undefined" || !response.found ) {
                        $("#emptySearchResults").removeClass("hidden").show();
                    } else {
                        $("#emptySearchResults").addClass("hidden").hide();
                    }

                }

                if( $(resultsContainer).length > 0 ) {
                    // Scroll down
                    $('html, body').animate({
                        scrollTop: $(resultsContainer).offset().top - 100
                    }, 1000);
                } else {
                    // Scroll down
                    $('html, body').animate({
                        scrollTop: $(button).offset().top
                    }, 1000);
                }

                // Remove the active class from the button
                $(button).removeClass('active');
            },
            error: function(){
                // Show a notification
                addNotification('There was an error sending the request', 'error');

                // Remove the active class
                $(button).removeClass('active');
            }
        });

        // Prevent the default
        event.preventDefault();
    });

	/**
	 * Input validation
	 * Performs validation on an input field
	 * this must be binded to the element
	 */
	function validateInput(){
		// Get the value
		var value = $(this).val();

		// Check if the field is required
		if( $(this).hasClass('required') && value.length == 0 ){
			// No value entered on a required field
			throw 'Field is required';
		}

		// Check if the field has a minimum length
		if( $(this).hasClass('min-length') && value.length < $(this).attr('min-length') ){
			// Minimum length requirement not met
			throw 'Must be at least ' + $(this).attr('min-length') + ' characters';
		}

		// Check if the field has a maximum length
		if( $(this).hasClass('max-length') && value.length > $(this).attr('max-length') ){
			// Passed max lenth
			throw 'Cannot be longer than ' + $(this).attr('max-length') + ' characters';
		}

		// Check if the field is alpha format
		if( $(this).hasClass('alpha') && value.match(/[^A-z\s]/ig) !== null ){
			// Not alpha
			throw 'No digits or symbols allowed';
		}

		// Check if the value has to be matched
		if( $(this).hasClass('match') ){
			// Get the value of the other input
			var match = $('#' + $(this).attr('match')).val();

			// Check if the values match
			if( value !== match ){
				// Values do not match
				throw 'Field does not match ' + $(this).attr('match');
			}
		}
	}

	/**
	 * Event listener
	 * Triggered when a required input value changed
	 */
	$('input.validate').on('input', function(){
		// Get the parent container
		var parent = $(this).closest('div.form-field');

		// Remove any classes
		parent.removeClass('valid error');

		try{
			// Validate the input field
			validateInput.call(this);

			// Validate passed
            parent.removeClass('error');
			parent.addClass('valid');
		}
		catch( e ){
			// Set the tooltip
			parent.find('.tooltip').text(e);

			// Could not validate the field
            parent.removeClass('valid');
			parent.addClass('error');
		}
	});

    /**
     * Event listener for select boxes
     * Triggered on change of a select box that has an ajax call
     */
    $('.input-select.ajax').on('change', function(){
        // Get the parent container
        var parent = $(this).closest('div.form-field');

        // Remove any classes
        parent.removeClass('show-spinner valid error');

        // Get the value
        var value = $(this).val();

        // Check if a timeout function has already been set
        var timeout = $(this).data('timeout');
        if( timeout ){
            // A timeout has already been set
            // Unset the time out
            clearTimeout(timeout);
        }

        // Check the length of the value
        if( value.length > 0 ){
            // Show the spinner
            parent.addClass('show-spinner');

            // Create a new timeout event
            timeout = setTimeout(function(){
                // Get the parent
                var parent = $(this).closest('div.form-field');

                // Send the ajax call
                $.ajax({
                    url      : $(this).data('ajax'),
                    data     : {
                        _ajax : true,
                        value : value
                    },
                    type     : "POST",
                    dataType : "json",
                    success  : function(response){

                        // Check the response
                        if( response.error ){
                            // The response has an error
                            parent.addClass('error');
                            // Set the tooltip
                            parent.find('.tooltip').text(response.response);
                        } else{

                            // Get the response
                            response = response.response;

                            // Check if there's a handler set
                            if( response.hasOwnProperty('handler') ){
                                // Get the handler
                                var handler = response.handler.charAt(0).toUpperCase() + response.handler.substr(1) + 'Handler';

                                // Check if the handler exists
                                if( typeof(window[handler]) === 'function' ){
                                    // Create the handler
                                    new window[handler](jQuery, this, response);
                                }
                            }
                            else{
                                // Valid response
                                parent.addClass('valid');
                            }

                            // The parent is no longer active
                            parent.removeClass('show-spinner');

                        }
                    }
                });
            }.bind(this), 450);

            // Save the timeout event
            $(this).data('timeout', timeout);
        }
        else{
            // Hide the spinner
            parent.find('div.spinner-container').removeClass('active');
        }
    });

	/**
	 * Event listener
	 * Triggered on keyup of an input that has an ajax call
	 */
	$('.input.ajax').on('input', function(){
		// Get the parent container
		var parent = $(this).closest('div.form-field');

		// Remove any classes
		parent.removeClass('show-spinner valid error');

		// Get the value
		var value = $(this).val();

		// Check if a timeout function has already been set
		var timeout = $(this).data('timeout');
		if( timeout ){
			// A timeout has already been set
			// Unset the time out
			clearTimeout(timeout);
		}

		// Check the length of the value
		if( value.length > 0 ){
			// Show the spinner
			parent.addClass('show-spinner');

			// Create a new timeout event
			timeout = setTimeout(function(){
				// Get the parent
				var parent = $(this).closest('div.form-field');

				// Send the ajax call
				$.ajax({
					url      : $(this).data('ajax'),
					data     : {
						_ajax : true,
						value : value
					},
					type     : "POST",
					dataType : "json",
					success  : function(response){
						// The parent is no longer active
						parent.removeClass('show-spinner');

						// Check the response
						if( response.error ){
							// The response has an error
							parent.addClass('error');

							// Set the tooltip
							parent.find('.tooltip').text(response.response);
						}
						else{
							// Valid response
							parent.addClass('valid');
						}
					}
				});
			}.bind(this), 450);

			// Save the timeout event
			$(this).data('timeout', timeout);
		}
		else{
			// Hide the spinner
			parent.find('div.spinner-container').removeClass('active');
		}
	});

    /***************************************************************
     * Autolist Section
     */
    function autolistSelect(result){
        // Check the result
        if( result.dataset.result ){
            // Get the parent
            var parent = $(result).closest('.form-field');

            // Find the input
            var input = parent.find('input.autolist');

            // Get the selected container
            var selected_container = parent.find('div.selected-container');

            // Send the select
            $.ajax({
                url      : input.data('source'),
                data     : {
                    _ajax  : true,
                    action : 'select',
                    value  : result.dataset.result
                },
                type     : "POST",
                dataType : "json",
                success  : function(response){
                    // Show the spinner
                    selected_container.addClass('has-selected');

                    // Check the response
                    if( response.error ){
                        // There was an error adding the user
                    }
                    else{
                        // Get the id
                        var id = parent.data('id');

                        // Get the value
                        var value = result.dataset.result;

                        // Create the new hidden input
                        parent.append('<input id="' + id + '-' + value + '" type="hidden" name="' + id + '[]" class="selected-values" value="' + value + '">');

                        // Parse the response
                        var render = $($.parseHTML('<div class="selected flex" data-value="' + value + '">' + response.response.render + '<div class="remove-selected"><i class="fas fa-times-circle"></i></div>'));

                        // Render the response
                        selected_container.append(render);

                        // Slide down
                        render.slideDown(300, function(){
                            // Show the item
                            $(this).addClass('show');
                        });

                        // Clear the input and the results
                        parent.find('input.autolist').val('');
                        parent.find('div.results').empty();

                        // Item has been added
                        parent.removeClass('show-spinner');
                    }
                },
                error    : function(){

                }
            });
        }
    }

    /**
     * Event listener
     * Triggered when an autolist's input changes
     */
    $('.input.autolist').on('input', function(){
        // Get the parent
        var parent = $(this).closest('.form-field');

        // Get the value
        var value = $(this).val();

        // Get the timeout
        var timeout = $(this).data('timeout');

        // Remove the timeout
        clearTimeout(timeout);

        // Check the length of the value
        if( value.length > 0 ){
            // Show the spinner
            parent.addClass('show-spinner');

            // Create a new timeout
            timeout = setTimeout(function(parent){
                // The selected values
                var selected_values = [];

                // Find the selected values input
                var selected_inputs = parent.find('input.selected-values');

                // Check the inputs
                if( selected_inputs.length > 0 ){
                    // Loop through the selected
                    for( var i = 0, length = selected_inputs.length; i < length; i++ ){
                        // Add the value
                        selected_values.push(selected_inputs.get(i).value);
                    }
                }

                // Send the ajax request
                $.ajax({
                    url      : $(this).data('source'),
                    data     : {
                        _ajax   : true,
                        action  : 'search',
                        value   : value,
                        limit   : $(this).data('limit') ? $(this).data('limit') : 5,
                        exclude : selected_values
                    },
                    type     : "POST",
                    dataType : "json",
                    success  : function(response){
                        // Remove the spinner
                        parent.removeClass('show-spinner');

                        // Parent has results
                        parent.addClass('has-results');

                        // Ge the results container
                        var results_container = parent.find('div.results-container');

                        // Get the results
                        var results = results_container.find('div.results');

                        // Clear the container
                        results.empty();

                        // Check the response
                        if( response.error ){
                            // There was an error with the response
                        }
                        else{
                            // Check if anything was returned
                            if( response.response.found > 0 ){
                                // Do not show the empty
                                results_container.addClass('hide-empty');

                                if( response.response.hasOwnProperty('direct-append') ) {

                                    results.append( response.response.list );

                                } else {

                                    // Loop through the results
                                    for( var i = 0, length = response.response.found; i < length; i++ ){
                                        // Check the value
                                        if( typeof(response.response.values[i]) === 'undefined' ){
                                            // Do nothing
                                            continue;
                                        }

                                        // Check the list
                                        if( typeof(response.response.list[i]) === 'undefined' ){
                                            // Do nothing
                                            continue;
                                        }

                                        // Render the result
                                        results.append('<div class="result" data-result="' + response.response.values[i] + '">' + response.response.list[i] + '</div>');
                                    }

                                }

                            }
                            else{
                                // No results found
                                // Show the empty set
                                results_container.removeClass('hide-empty');
                            }
                        }
                    },
                    error    : function(){
                        // Set the field to error
                        parent.removeClass('show-spinner');
                        parent.addClass('error');

                        // Add the tooltip
                        parent.find('.tooltip').text('Could not send the request');
                    }
                });
            }.bind(this, parent), 200);

            // Save the timeout
            $(this).data('timeout', timeout);
        }
        else{
            // Parent does not have a result
            parent.removeClass('has-results');

            // Clear the results
            parent.find('div.results').empty();
        }
    });

    /**
     * Event listener
     * Triggers when a key is pressed on an autolist
     */
    $('.input.autolist').on('keydown', function(event){
        // Check the keycode
        if( event.keyCode === 40 || event.keyCode === 38 ){
            // Prevent the default behaviour
            event.preventDefault();

            // Get the results container
            var results_container = $(this).parent().find('div.results-container');

            // Check if the container is being hovered over
            if( results_container.find('.result:hover').length === 0 ){
                // Get the results
                var results = results_container.find('div.result');

                // Check the results
                if( results.length > 0 ){
                    // Get the selected
                    var selected = results_container.find('div.selected');

                    // The current item selected
                    var current = null;

                    // Check the keycode
                    if( event.keyCode === 40 ){
                        // Down arrow key pressed
                        // Check the selected
                        if( selected.length === 0 ){
                            // Select the first child
                            current = results.first();
                        }
                        else{
                            // Select the next child
                            current = selected.next();
                        }

                        // Check the current
                        if( current.length === 0 ){
                            // Set the selected
                            current = results.first();
                        }
                    }
                    else if( event.keyCode === 38 ){
                        // Up arrow key was pressed
                        // Check the selected
                        if( selected.length === 0 ){
                            // Get the last child
                            current = $(results.last());
                        }
                        else{
                            // Select the previous child
                            current = selected.prev();
                        }

                        // Check the current
                        if( current.length === 0 ){
                            // Set the last element
                            current = results.last();
                        }
                    }

                    // Remove the class from selected
                    selected.removeClass('selected');

                    // Set the current
                    current.addClass('selected');
                }
            }
        }
        else if( event.keyCode === 13 ){
            // Get the results container
            var results_container = $(this).parent().find('div.results-container');

            // Get the selected
            var selected = results_container.find('div.result.selected');

            // Check if there's a selected value
            if( selected.length > 0 ){
                // Prevent the default behaviour
                event.preventDefault();

                // Select the result
                autolistSelect(selected.get(0));
            }
            else{

                // Ge the parent
                var parent = $(this).closest('div.form-field');

                // Get the selected container
                var selected_container = parent.find('div.selected-container');

                // Check if the item can create
                if( parent.hasClass('create') ){

                    // Show the spinner
                    parent.addClass('show-spinner');

                    // Prevent the default behaviour
                    event.preventDefault();

                    // Send the create request
                    $.ajax({
                        url      : $(this).data('source'),
                        data     : {
                            _ajax  : true,
                            action : 'create',
                            value  : $(this).val()
                        },
                        type     : "POST",
                        dataType : "json",
                        success  : function(response){

                            if( response.error ) {

                                // Set the field to error
                                parent.addClass('error');

                                // Add the tooltip
                                parent.find('.tooltip').text(response.response);

                            } else {
                                // Get the id
                                var id = parent.data('id');

                                // Get the value
                                var value = response.response.value;

                                // Create the new hidden input
                                parent.append('<input id="'+ id + '-' + value + '" type="hidden" name="' + id + '[]" class="selected-values" value="' + value + '">');

                                // Parse the response
                                var render = $($.parseHTML('<div class="selected" data-value="' + value + '">' + response.response.render + '<div class="remove-selected"><i class="material-icons icon">clear</i></div></div>'));

                                // Render the response
                                selected_container.append(render);

                                // Add selected class
                                selected_container.addClass("has-selected");

                                // Slide down
                                render.slideDown(300, function(){
                                    // Show the item
                                    $(this).addClass('show');
                                });

                                // Clear the input and the results
                                parent.find('input.autolist').val('');

                            }

                            // remove spinner
                            parent.removeClass('show-spinner');
                            // remove the not found set
                            // Parent does not have a result
                            parent.removeClass('has-results');
                            // Clear the results
                            parent.find('div.results').empty();

                        },
                        error    : function(){
                        }
                    });
                }
            }
        }
    });

    $('div.results-container').on('click', 'div.result', function(){
        // Select the result
        autolistSelect(this);
    });

    $('div.results-container').on('mouseover', 'div.result', function(){
        // Get the selected
        var selected = $(this).parent().find('div.result.selected');

        // Check if there's an item selected
        if( selected.length > 0 ){
            // Remove the class
            selected.removeClass('selected');
        }
    });

    $('div.selected-container').on('click', 'div.remove-selected', function(){
        // Get the selected item
        var selected = $(this).closest('div.selected');

        // Find the parent
        var parent = $(this).closest('div.form-field');

        // Remove the input
        parent.find('#' + parent.data('id') + '-' + selected.data('value')).remove();

        // Hide the selected
        selected.addClass('hide');

        // Remove the selected
        selected.delay(450).slideUp(300, function(){
            // Get the parent
            var parent = $(this).parent();

            // Remove the child
            this.remove();

            // Check the number of children the parent has
            if( parent.children().length == 0 ){
                // Remove the parent class
                parent.removeClass('has-selected');
            }
        });
    });

	$("body").on('click', '.form-button', function(event){
		// Check if the button is active
		if( $(this).hasClass('active') ){
			// Prevent the default
			event.preventDefault();
			return false;
		}

		// Get the form
		var form = $(this).closest('.form');

		// Remove any error class
		form.removeClass('error');

		// Set the button as active
		$(this).addClass('active');

		// Save the an reference to the button
		var button = this;

		// Passed validation or not
		var passed = true;

		// Get the fields in the form
		var fields = form.find('.input');
		var i = 0;

		for( var length = fields.length; i < length; i++ ){

            // Get the parent of the input
            var parent = $(fields[i]).closest("div.form-field");
			try{

				// Validate the input
				validateInput.call(fields[i]);

                // Validate passed
                parent.removeClass('error');
                parent.addClass('valid');
			}
			catch( e ){

				//var parent = $(fields[i]).parent();

				// Set the error
				parent.find('.tooltip').text(e);
				parent.addClass('error');
                parent.removeClass('valid');

				// Failed validation
				passed = false;
			}
		}

		// Check if the validation passed
		if( passed === false ){
			// Validation failed
			// Caught an error
			$(this).removeClass('active');
			// Notify
            addNotification("Please make sure to provide all the required fields", "error");
			// Prevent the default
			event.preventDefault();
			return false;
		}

		// Check if the form is ajaxed
		if( form.hasClass('ajax') ){
			// Serialize the data
			var data = '_ajax=&' + form.serialize();

			// Get the action of the form
			var action = form.attr('action');

			// Send the ajax request
			$.ajax({
				url      : action,
				data     : data,
				type     : 'POST',
				dataType : 'json',
				success  : function(response){
					// Get the response container
					var container = form.find('div.form-response');

					// Empty the container, and remove the classes
					container.empty();
					container.removeClass('success error');

					// Check the response
					if( response.error ){
						// Add the error class to the form
						form.addClass('error');

						// Check the container
						if( container.length > 0 ){
							// There was an error submitting the form
							// Add the error class
							container.addClass('error');

							// Add the response
							container.append(response.response);
						}
						else{
							// Add a notification with the response
							addNotification(response.response, 'error', 3000);
						}
					}
					else{
						// Check if the form has a handler
						if( form.data('handler') && typeof window[form.data('handler')] === 'function'){
							// Create the handler
							new window[form.data('handler')]($, button, response.response);
						}
						else{
							// Check the response type
							if( (typeof response.response) === 'string' ){
								// Show the success message
                                // Check the container
                                if( container.length > 0 ){
                                    container.addClass('success');

                                    // Add the response
                                    container.append(response.response);
                                }
                                else{
                                    // Add a notification with the response
                                    addNotification(response.response, 'success', 3000);
                                }
							}
							else{

                                // Get the response
                                response = response.response;

                                // Check response handlers
                                if( response.hasOwnProperty('submitted') ){
                                    // Clear the form
                                    form.trigger('reset');
                                    // Add a notification with the response
                                    addNotification(response.submitted, 'success', 5000);
                                } else if( response.hasOwnProperty('handler') ){
                                    // Get the handler
                                    var handler = response.handler.charAt(0).toUpperCase() + response.handler.substr(1) + 'Handler';

                                    // Check if the handler exists
                                    if( typeof(window[handler]) === 'function' ){
                                        // Create the handler
                                        new window[handler]($, button, response);
                                    }
                                } else {
                                    // Create a generic handler
                                    // Let the handler deal with the response
                                    new Handler($, button, response);
                                }

							}
						}
					}

					// Remove the active class from the button
					$(button).removeClass('active');
				},
				error: function() {
                    // Show a notification
                    addNotification('There was an error sending the request', 'error');

                    // Remove the active class
                    $(button).removeClass('active');
                }
			});

			// Prevent the default
			event.preventDefault();
			return false;
		}
	});

	$("div.form-field").find('div.select').on('click', function(){
		$(this).toggleClass('active');
	});

	function imageUpload(handle, target){
		// Get the files
		var file = handle.get(0).files[0];

		if(!__checkFileSize(file)) {
            // Display the error
            var msg = "File is too big. Allowed size is 8MB";

            // Show notification
            addNotification(msg, 'error');

            return;
        }

		// Create the form
		var form_data = new FormData();

		// Add the file
		form_data.append('file', file);

		// Send the ajax request
		$.ajax({
			url         : this.dataset.upload,
			data        : form_data,
			dataType    : 'json',
			type        : 'POST',
			cache       : false,
			processData : false,
			contentType : false,
			success     : function(handle, target, file, response){
				// Check the response
				if( response.error ){
					// Could not upload
                    addNotification(response.response, "error");
				}
				else{
					// No error found
					// Update the value
					this.value =  response.response;

					// Create a file reader
					var reader = new FileReader();

					// Set the on load function
					reader.onload = function(event){
						// Show the preview
						this.css('background-image', 'url(' + event.target.result + ')');
					}.bind(target);

					// Read the file
					reader.readAsDataURL(file);

					// Target now has image
					target.addClass('has-image');

					// Remove the handle
					handle.remove();
				}
			}.bind(this, handle, target, file)
		});
	}

	$('input.form-image').each(function(){
		// Get the trigger, target, upload, and delete
		var trigger = this.dataset.trigger;
		var target = this.dataset.target;
		var upload = this.dataset.upload;
		var del = this.dataset.delete;

		// Check the trigger
		if( trigger === undefined || trigger.length === 0 ){
			// Missing trigger
			throw 'Form image is missing trigger';
		}

		// Check the target
		if( target === undefined || target.length === 0 ){
			// Missing target
			throw 'Form image is missing target';
		}

		// Check the upload
		if( upload === undefined || upload.length === 0 ){
			// Missing target
			throw 'Form image is missing upload';
		}

		// Check the delete
		if( del === undefined || del.length === 0 ){
			// Missing target
			throw 'Form image is missing delete';
		}

		// Get the trigger and target
		trigger = $(trigger);
		target = $(target);

		// Add the trigger class
		trigger.addClass('trigger');

		// Set the event handler
		trigger.on('click', function(){
			// Get the handle
			var handle = $('#' + this.dataset.id + '-handle');

			// Get the target
			var target = $(this.dataset.target);

			// Check if the target has an image
			if( target.hasClass('has-image') ){
				// Delete the image
				$.ajax({
					url      : this.dataset.delete,
					data     : {
						id: this.value
					},
					dataType : 'json',
					type     : 'POST',
					success: function(target, response){
						// Check the response
						if( response.error ){
							// Could not delete the image
                            addNotification(response.response, "error");
						}
						else{
							// Clear the input
							this.value = '';

							// Clear the target
							target.removeClass('has-image');

							// Clear the background
							target.css('background-image', 'none');
						}
					}.bind(this, target)
				});
			}
			else{
				// Check the input
				if( handle.length === 0 ){
					// Get the parent
					var parent = $(this.parentElement);

					// Create a new input
					handle = $.parseHTML('<input id="' + this.dataset.id + '-handle" type="file">');

					// Set the handle
					handle = $(handle);

					// Set the event handler
					handle.on('change', imageUpload.bind(this, handle, target));

					// Append the input
					parent.append(handle);
				}

				// Trigger the click
				handle.trigger('click');
			}
		}.bind(this));
	});

    function fileUpload(handle, target, previewer, owner){
        // Get the files
        var file = handle.get(0).files[0];

        // Previewer is set
        if(file && previewer && !__checkFileSize(file)) {
            // Display the error
            var msg = "File is too big. Allowed size is 8MB";
            // Show notification
            addNotification(msg, 'error');
            return;
        }

        // Create the form
        var form_data = new FormData();

        // Add the file
        form_data.append('file', file);
        // Add owner info
        if(owner) {
            form_data.append('user', owner);
        }

        // Add type if any
        if(handle.hasClass("image")) {
            form_data.append('type', 'image');
        }

        // Send the ajax request
        $.ajax({
            url         : this.dataset.upload,
            data        : form_data,
            dataType    : 'json',
            type        : 'POST',
            cache       : false,
            processData : false,
            contentType : false,
            success     : function(handle, target, file, response){
                // Check the response
                if( response.error ){
                    // Could not upload
                    addNotification(response.response, "error");
                }
                else{
                    // No error found
                    // Update the value
                    this.value =  response.response;

                    if(previewer) {
                        // Create a file reader
                        var reader = new FileReader();

                        // Set the on load function
                        reader.onload = function(event){
                            // Show the preview
                            $(previewer).attr('src', event.target.result);
                        }.bind(target);

                        // Read the file
                        reader.readAsDataURL(file);
                    }

                    // Target now has image
                    target.addClass('has-file');

                    target.find(".filename").html(file["name"]);
                    target.find(".action").html("<i class='material-icons icon'>close</i>").removeClass("upload").addClass("delete");
                    addNotification("Upload Successful", "success");

                    // Remove the handle
                    handle.remove();
                }
            }.bind(this, handle, target, file)
        });
    };

    $('input.file-uploader').each(function(){
        // Get the trigger, target, upload, and delete
        var trigger = this.dataset.trigger;

        var parentUploader = $(this).closest("div.file-upload-wrapper");
        // target is the parent of the trigger
        var target = parentUploader.find("div"+this.dataset.trigger).parent();
        var upload = this.dataset.upload;
        var del = this.dataset.delete;
        var type = this.dataset.type;
        var previewer = this.dataset.hasOwnProperty("preview") ? this.dataset.preview : false;
        var owner = this.dataset.hasOwnProperty("owner") ? this.dataset.owner : false;

        trigger = parentUploader.find("div"+this.dataset.trigger);

        // Check the trigger
        if( trigger === undefined || trigger.length === 0 ){
            // Missing trigger
            throw 'Form file is missing trigger';
        }

        // Check the target
        if( target === undefined || target.length === 0 ){
            // Missing target
            throw 'Form file is missing target';
        }

        // Check the upload
        if( upload === undefined || upload.length === 0 ){
            // Missing target
            throw 'Form file is missing upload';
        }

        // Check the delete
        if( del === undefined || del.length === 0 ){
            // Missing target
            throw 'Form file is missing delete';
        }

        // Get the trigger and target
        trigger = $(trigger);
        target = $(target);

        // Add the trigger class
        trigger.addClass('trigger');

        // Set the event handler
        trigger.on('click', function(){
            // Get the handle
            var handle = $('#' + this.dataset.id + '-handle');
            var type = this.dataset.type;
            var target = trigger.parent();
            // Check if the target has an image
            if( target.hasClass('has-file') ){
                // Delete the image
                $.ajax({
                    url      : this.dataset.delete,
                    data     : {
                        action: this.dataset.action,
                        post:   this.dataset.id,
                        id: this.value
                    },
                    dataType : 'json',
                    type     : 'POST',
                    success: function(target, response){
                        // Check the response
                        if( response.error ){
                            // Could not delete the image
                            addNotification(response.response, "error");
                        }
                        else {
                            // Clear the input
                            this.value = '';

                            // Clear the target
                            target.removeClass('has-file');

                            target.find(".filename").html("Upload a file...");
                            target.find(".action").html("<i class='material-icons icon'>file_upload</i>").removeClass("delete").addClass("upload");
                            target.find(".view").remove();
                        }

                    }.bind(this, target)
                });
            }
            else{
                // Check the input
                if( handle.length === 0 ){
                    // Get the parent
                    var parent = $(this.parentElement);

                    // Create a new input
                    handle = $.parseHTML('<input id="' + this.dataset.id + '-handle" type="file" class="hidden '+ type +'">');

                    // Set the handle
                    handle = $(handle);

                    // Set the event handler
                    handle.on('change', fileUpload.bind(this, handle, target, previewer, owner));

                    // Append the input
                    parent.append(handle);

                    //handle.trigger("click");
                }

                // Trigger the click
                handle.trigger('click');
            }
        }.bind(this));
    });

    var uploader = new window.Uploader('.uploader');

});
