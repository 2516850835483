let register_form = document.querySelector('#register form')

if (register_form != null) {
    let select_type = document.querySelector('form .select-user-type'),
        change = new Event('change')

    form_manipulor(select_type)

    // Trigger the select change
    select_type.dispatchEvent(change)
}

function form_manipulor(select_type) {
    let fname = document.querySelector('form #first_name'), //always
        lname = document.querySelector('form #last_name'), //always
        email = document.querySelector('form #email'), //always
        pass = document.querySelector('form #password'), //always
        // loc_label = document.querySelector('form [for="region"]'), // Independent only
        // bname_label = document.querySelector('form [for="business"]'), // Independent only
        // firm_label = document.querySelector('form [for="firm"]'), // Independent only
        phone_label = document.querySelector('form [for="phone"]'), // Independent only
        // location = document.querySelector('form #region'), // Independent only
        phone = document.querySelector('form #phone'), // firm + Independent only
        bname = document.querySelector('form #business'), // Business only
        firm = document.querySelector('form #firm'), // Business only
        partner = document.querySelector('form .business-wrapper'), // Business only
        membershipWrapper = document.querySelector('form .membership-wrapper')

    select_type.addEventListener('change', function (e) {
        e.preventDefault()
        switch (e.target.value) {
            case 'firm':
                fname.classList.add('required')
                fname.classList.add('validate')

                lname.classList.add('required')
                lname.classList.add('validate')

                firm.parentNode.parentNode.parentNode.classList.remove('hide');
                // firm.classList.remove('hide')
                firm.classList.add('required')
                firm.classList.add('validate')

                bname.parentNode.parentNode.parentNode.classList.add('hide');
                partner.classList.add('hide');
                // bname.classList.add('hide')
                // bname_label.classList.add('hide')

                phone.classList.remove('hide')
                phone_label.classList.remove('hide')

                // Show membership
                membershipWrapper.classList.remove('hide');

                bname.parentNode.parentNode.parentNode.style.marginBottom = '0';
                phone.parentNode.parentNode.parentNode.style.marginBottom = '19px';
                firm.parentNode.parentNode.parentNode.style.marginBottom = '19px';

                break;
            case 'business':
                fname.classList.remove('required')
                fname.classList.remove('validate')

                lname.classList.remove('required')
                lname.classList.remove('validate')

                partner.classList.remove('hide');
                bname.parentNode.parentNode.parentNode.classList.remove('hide');
                bname.classList.add('required')
                bname.classList.add('validate')

                phone.classList.add('hide')
                phone_label.classList.add('hide')

                // Hide membership
                membershipWrapper.classList.add('hide');

                // firm_label.classList.add('hide')
                firm.parentNode.parentNode.parentNode.classList.add('hide');
                firm.classList.remove('required')
                firm.classList.remove('validate')
                // firm.classList.add('hide')

                // NOTE: remove mb from parent div
                bname.parentNode.parentNode.parentNode.style.marginBottom = '19px';
                phone.parentNode.parentNode.parentNode.style.marginBottom = '0';
                firm.parentNode.parentNode.parentNode.style.marginBottom = '0';
                break;
            case 'consultant':
            default:
                // location.classList.remove('hide')

                fname.classList.add('required')
                fname.classList.add('validate')

                lname.classList.add('required')
                lname.classList.add('validate')

                phone_label.classList.remove('hide')
                phone.classList.remove('hide')

                // firm_label.classList.add('hide')
                // firm.classList.add('hide')
                firm.parentNode.parentNode.parentNode.classList.add('hide');
                firm.classList.remove('required');
                firm.classList.remove('validate');
                // Show membership
                membershipWrapper.classList.remove('hide');

                // bname_label.classList.add('hide')
                // bname.classList.add('hide')
                bname.parentNode.parentNode.parentNode.classList.add('hide');
                partner.classList.add('hide');

                bname.parentNode.parentNode.parentNode.style.marginBottom = '0';
                phone.parentNode.parentNode.parentNode.style.marginBottom = '19px';
                firm.parentNode.parentNode.parentNode.style.marginBottom = '0';
        }
    })
}