jQuery(document).ready(function($){
    /**
     * Embed url input handler
     */
    $("#profile").on("input", ".embed", function(ev) {
        // Save the box
        var box = $(this);

        // Check for previous processing
        if( box.hasClass("processing") ) {
            return;
        }

        // Get the form
        var form = box.closest("form");

        // Check the action
        var action = this.dataset.action;
        if( !action ) {
            return;
        }

        // Get the target element
        var target = form.find(".embed-preview-container");
        if( target.hasClass("has-preview") ) {
            return;
        }

        // Get the contents
        var contents = box.val();
        if( contents.length < 1 ) {
            return;
        }

        var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
        // Filtering URL from the content using regular expressions
        var matches = contents.match(urlRegex);

        // Check
        if( matches != null ) {
            var url = '';

            // only proceed with embed if the field contains more than 11 characters
            // Example: http://a.io is 11 chars
            $.each(matches, function(index, value){
                // Get the first one
                if ( value.length > 11 || value.match(/^http(s)?:\/\//) ) {
                    url = value;
                    return false;
                }
            });

            // Add processing
            box.addClass("processing");
            target.addClass("processing");

            // Now send the request
            $.ajax({
                url      : action,
                data     : {
                    action: 'embed',
                    source: url
                },
                type     : 'POST',
                dataType : 'json',
                success  : function(response){
                    // Check the response
                    if( response.error ){
                        // Add a notification with the response
                        addNotification(response.response, 'error', 3000);
                    } else {
                        // Check the response type
                        if( (typeof response.response) === 'string' ){
                            // Don't do anything
                        } else{
                            // Get the response
                            response = response.response;
                            if( response.hasOwnProperty('append') ) {
                                // Add to handler
                                target.html( response.append );
                                target.addClass("has-preview");
                            }
                        }
                    }
                    // Remove processing
                    box.removeClass("processing");
                    target.removeClass("processing");

                },
                error: function() {
                    // Remove processing
                    box.removeClass("processing");
                    target.removeClass("processing");
                }
            });
        }

        ev.preventDefault();
    });
});