(function(){
    /**
     * Friend Handler
     * Handles friend request response
     * @param response
     * @constructor
     */
    window.FriendHandler = function(jquery, source, response){
        // Initialize the handler
        Handler.call(this, jquery, source, response);
    }

    // Set the inheritance
    window.FriendHandler.prototype   = Object.create(window.Handler.prototype);
    window.FriendHandler.constructor = FriendHandler;

    /**
     * Initializes the handler
     * @param response The response from the handler
     */
    FriendHandler.prototype.initialize = function(response){

        // Add the actions allowed
        this._allowed.push('completed');
        this._allowed.push('canMessage');

        // Prepare the response
        this.prepare(response);

        // Run the response
        this.run();
    }

    /**
     * Request is accepted
     * Show the message button after
     */
    FriendHandler.prototype.canMessage = function() {
        // Build message
        var message = this._data.message;
        // Get parent row
        var parent = this._source.closest("div.row");
        // Get the html
        var html = this._data.data;
        // Add
        parent.html(html);
        // Show the notification
        addNotification( message, 'success');
    }

    /**
     * Request is accepted or rejected
     */
    FriendHandler.prototype.completed = function(){

        // Build message
        var message = this._data.message;

        // Find the card
        var card = this._source.closest('div.card-notification');;
        // get parent
        let parent = $('#dash-notifications .top.container')
        // .find all card-Notifications
        let notifications = parent.find('div.card-notification'),
            no_result = parent.find('.no-results'),
            length = notifications.length

        // Remove card
        card.slideUp(300);

        setTimeout(function(){
            card.remove();
        }, 400);

        // Show the notification
        addNotification( message, 'success');

        // if 0 remove class hide to no-results
        if (length <= 1) {
          setTimeout(function () {
            no_result.removeClass('hide')
          }, 500)
        } else {
          no_result.addClass('hide')
        }

    }

}());
