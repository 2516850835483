jQuery(document).ready(function($){

    /**
     * Ajax Request handler
     */
    $("main").on("click", ".action", function(ev){

        // save the action
        var trigger = $(this);

        if( trigger.hasClass("active") ) {
            return;
        }

        // Remove active
        trigger.removeClass("active");

        if( trigger.hasClass("ajax") ) {

            // Activate it
            trigger.addClass("active");

            $.ajax({
                url      : $(this).data('request'),
                data     : {
                    _ajax  : true,
                    action : trigger.data('action'),
                    value  : trigger.data('value')
                },
                type     : "POST",
                dataType : "json",
                success  : function(response){

                    // Remove active
                    trigger.removeClass("active");

                    if( response.error ) {
                        // Show the error
                        addNotification(response.response, 'error');
                        return;
                    }

                    if( typeof( response.response ) == "object" ) {

                        if( response.response.hasOwnProperty('handler') ){
                            // Get the response
                            response = response.response;

                            // Get the handler
                            var handler = response.handler.charAt(0).toUpperCase() + response.handler.substr(1) + 'Handler';

                            // Check if the handler exists
                            if( typeof(window[handler]) === 'function' ){
                                // Create the handler
                                new window[handler](jQuery, this, response);
                            }
                        }
                        else{

                            // Check for redirect
                            if( response.response.hasOwnProperty('redirect') ) {
                                window.location.href = response.response.redirect;
                            }

                            for( var i in response.response ){
                                if( i === "hide_trigger" && response.response[i] === true ){
                                    trigger.remove();
                                }
                            }
                        }

                    }

                }.bind(this),
                error    : function(){
                    // Could not finish the request
                    addNotification('There was an error sending the request', 'error');
                    // Remove active
                    trigger.removeClass("active");
                }

            });

            ev.preventDefault();
        }

    });
});