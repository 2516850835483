
(function($){
	$.fn.wordCounter = function(options) {
		// setting the defaults
		// $("textarea").textareaCounter({ limit: 100 });
		let defaults = {
			limit: 120,
      maxlength: 800
		};
		var options = $.extend(defaults, options)

		// and the plugin begins
		return this.each(function() {
			let obj, text, wordcount, limited

			obj = $(this)
      obj.attr('maxlength', options.maxlength)
			obj.after(`<span class='mt-2 text-12' style="clear: both;" id="counter-text">Max. ${options.limit} words</span>`)
      // console.log('Hello',obj.val());
      // if ( obj.val() >= options.limit) {
      //   $("#counter-text").html((options.limit - obj.val())+' words left');
      // }


      obj.keyup(function() {
			    text = obj.val();
			    if(text === "") {
			    	wordcount = 0;
			    } else {
				    wordcount = $.trim(text).split(" ").length;
				}
			    if(wordcount >= options.limit) {
		        $("#counter-text").html('<span style="color: #DD0000;">0 words left</span>');
    				limited = $.trim(text).split(" ", options.limit);
    				limited = limited.join(" ");
    				$(this).val(limited);
			    } else {
  	        $("#counter-text").html((options.limit - wordcount)+' words left');
			    }
			})
		})
	}
})(jQuery);


$("textarea.bio").wordCounter();
$("textarea.description").wordCounter({maxlength: 350});
