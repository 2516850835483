jQuery(document).ready(function($) {

    /**
     * Edit featured application
     */
    $("form input#featured").on("change", function() {
        // Get the payment info element
        var paymentInfoEl = $("form #payment-step");
        if(this.checked) {
            paymentInfoEl.slideDown();
        } else {
            paymentInfoEl.slideUp();
        }
    });

    /**
     * Opportunity Post to specific member toggle handler
     */
    $("form input#visibility").on("change", function(ev) {
        // Get the member selector
        var selector = $("#visibility-users").closest(".fields-container");
        if(this.checked) {
            selector.slideDown().removeClass("hide");
        } else{
            selector.slideUp();
        }
    });

    /**
     * Handles the opportunity status change
     */
    $("form select#status").on("change", function() {
        // Get the selected value label
        var status = $("select#status option:selected").text();
        // Get the closed status reason
        var closedStatusInput = $("div.closed-status-reason");
        // Get the payment info element
        var paymentInfoEl = $("#payment-step");
        var statusChangeBox = $("div.status-change-text");
        paymentInfoEl.slideUp();
        // Show closed status reason input box
        if(status === "Closed" || status === "Awarded") {
            closedStatusInput.slideDown();
            closedStatusInput.find("#closed_reason").addClass("required");
            statusChangeBox.slideUp();
        } else if(status === "Open") {
            paymentInfoEl.slideDown();
            // Show the charge status
            statusChangeBox.slideDown();
        } else {
            closedStatusInput.slideUp();
            closedStatusInput.find("#closed_reason").removeClass("required");
            statusChangeBox.slideUp();
        }
    });

    /**
     * On featured application switch change
     * Shows the payment info box
     */
    $("form").find("input[name=featured]").on("change", function() {

        // Get the form
        var form = $(this).closest(".form");

        // Find the payment box
        var paymentBox = form.find("#payment-info");

        // Hide the payment info
        paymentBox.slideUp();

        // Only when its checked
        if(this.checked) {
            // Show payment box
            paymentBox.slideDown();
        }

    });

});