/*
Toggle content by checking radio buttons or checkboxes
*/

jQuery(document).ready(function ($) {

    $('.js-toggle').each(function () {
        if($(this).is(':checked')) {
            var target = $(this).attr('data-toggle');
            if (target.length) {
                $('.'+target).show(); // show target content
            }
        } else {
            var target = $(this).attr('data-toggle');
            if (target.length) {
                $('.'+target).hide(); // show target content
            }
        }
    });

    $('.js-toggle').change(function () {

        $(this).closest('.js-toggle-wrapper').find('.js-toggle-content').hide(); // hide all togglable contents in toggle wrapper
        var target = $(this).attr('data-toggle');

        if (target.length) {
            $('.'+target).show(); // show target content
        }

    });

});

