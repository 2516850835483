(function(){
	/**
	 * Pagination Handler
	 * Handles pagination responses
	 * @param response
	 * @constructor
	 */
	window.PaginationHandler = function(jquery, source, response){
		// Initialize the handler
		Handler.call(this, jquery, source, response);
	}

	// Set the inheritance
	window.PaginationHandler.prototype   = Object.create(window.Handler.prototype);
	window.PaginationHandler.constructor = PaginationHandler;

	PaginationHandler.prototype.afterAppend = PaginationHandler.prototype.afterPrepend = function(items){
        // Check if there's a more
		if( this._data.hasOwnProperty('more') ){

            if(  this._data['more'] == false ) {
                // No more items
                // Hide the more button
                this._source.slideUp(400);
            } else {
                this._source.slideDown(400);
            }

			// Update the page
			this._source.data('page', this._source.data('page') + 1);
		}

        // Check for scroll
        if( this._data.hasOwnProperty('scroll') ){

            // Scroll down
            $('html, body').animate({
                scrollTop: $(''+this._data['scroll']).offset().top - 100
            }, 1000);

        } else if( this._source.hasClass('scroll') ) {
            var parent = $(".animation-wrapper").parent();
            var el = parent.find(".animation-wrapper").last();
            $('html, body').animate({
                scrollTop: el.offset().top - 100
            }, 1000);
        }

        // If we need to update the time
        if( this._data.hasOwnProperty('locale') ) {
            // Loop through the items
            for( var i = 0, length = items.length; i < length; i++ ){
                if( items[i].hasClass("animation-wrapper") ) {
                    var childItems = items[i].children();
                    for( var j=0; j < childItems.length; j++ ) {
                        localeTime($(childItems[j]).find('time'));
                    }
                } else {
                    // Update the time
                    localeTime(items[i].find('time'));
                }
            }
        }
	}

    /**
     * Initializes the handler
     * @param response The response from the handler
     */
    PaginationHandler.prototype.initialize = function(response){
        // Add the actions allowed
        this._allowed.push('reset');

        // Prepare the response
        this.prepare(response);

        // Run the response
        this.run();
    }

    /**
     * Reset the handler
     */
    PaginationHandler.prototype.reset = function(){
        $(""+this._current.action).data("page", 0);
        if( this._data.hasOwnProperty('more') ){
            if(  this._data['more'] ) {
                $(""+this._current.action).slideDown(400);
            }
        }
    }

}());
