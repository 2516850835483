jQuery(document).ready(function($) {
    // Colorpicker
    if ($('input.colorpicker').length != 0) {
        $.each($("input.colorpicker"), function(a, b) {
            $(this).spectrum({
                preferredFormat: "hex",
                showInput: true,
            });
        });
    }
});