jQuery(document).ready(function($) {

    // Membership type
    $("#membership_payment_type").on("change", function(ev)
    {
        var monthlyBox = $("#summary-box-monthly");
        var annualBox = $("#summary-box-annual");
        var summaryMonthlyBox = $("#payment-summary-monthly");
        var summaryAnnualBox = $("#payment-summary-annual");
        if(monthlyBox && annualBox) {
            var selection = $(this).val();
            if(selection === "Annual") {
                monthlyBox.slideUp();
                annualBox.slideDown();
                summaryMonthlyBox.slideUp();
                summaryAnnualBox.slideDown();
            } else {
                annualBox.slideUp();
                monthlyBox.slideDown();
                summaryMonthlyBox.slideDown();
                summaryAnnualBox.slideUp();
            }
        }
    });

    // Offer dropdown
    $("#main-offers").on("click", ".cat-arrow", function(ev) {
        ev.preventDefault();
        var sub = $(this).closest("li").find("> ul");
        if ($(this).hasClass("open")) {
            $(this).removeClass("open");
            sub.slideUp();
        } else {
            $(this).addClass("open");
            sub.slideDown();
        }
    });

    // Business profile description checkbox
    $("input#use_business_description-business").on("change", function(ev) {
        var container = $(this).closest(".field-wrap");
        if($(this).is(":checked")) {
            container.find("textarea").attr("disabled", "true");
            container.find("textarea").removeClass("required");
        } else {
            container.find("textarea").removeAttr("disabled");
            container.find("textarea").addClass("required");
        }
    });

    // Confirmation dialog
    $(".confirm-action").on("click", function(ev) {
        var hasConfirm = this.dataset.confirm;
        if( hasConfirm ) {
            if( !confirm(hasConfirm) ) {
                ev.preventDefault();
                return false;
            }
        }
    });

    // Registration membership payment form
    $("#register select#membership").on("change", function() {
        var form = $(this).closest("form");
        var paymentBox = form.find("div.payment-info");
        if($(this).val() != "1" && $(this).val() != "") { // Not a basic membership
            paymentBox.slideDown();
        } else {
            paymentBox.slideUp();
        }
    });

    // Tab
    $(".tabs-panel .tab").on("click", function(ev) {
        ev.preventDefault();

        var panel = $(this).closest(".tabs-panel");
        var target = $(this).attr("data-target");

        panel.find(".tab").removeClass("active");
        panel.find(".tab-content").removeClass("active");

        $(this).addClass("active");
        panel.find(target).addClass("active");
    });

    $(".tabs-panel .left-arrow").on("click", function(ev) {
        ev.preventDefault();

        var panel = $(this).closest(".tabs-panel");
        var nav = panel.find("nav.tabs");
        var counter = nav.find(".tab").length;

        var current = panel.find(".tabs .active");
        var elementIndex = current.index();

        if(elementIndex == 0) {
            elementIndex = counter - 1;
            var element = nav.find(":eq("+ elementIndex +")");
        } else {
            var element = current.prev();
        }

        element.trigger("click");
    });

    $(".tabs-panel .right-arrow").on("click", function(ev) {
        ev.preventDefault();

        var panel = $(this).closest(".tabs-panel");
        var nav = panel.find("nav.tabs");
        var counter = nav.find(".tab").length;

        var current = panel.find(".tabs .active");
        var elementIndex = current.index();

        if(elementIndex == counter - 1) {
            elementIndex = 0;
            var element = nav.find(":eq("+ elementIndex +")");
        } else {
            var element = current.next();
        }

        element.trigger("click");
    });

    // Renewal content payment form
    $("#edit-rep input#renew").on("change", function() {
        var paymentBox = $("div#renewal_payment");
        if($(this).is(":checked")) {
            paymentBox.slideDown();
        } else {
            paymentBox.slideUp();
        }
    });

    // Rating
    $("#ratings").on("click", "p.stars a", function() {

        // Find the form
        var form = $(this).closest("form");

        // Get the selected rating
        var rating = this.dataset.rating;

        // Get the container
        var container = $(this).closest(".stars");

        // Get the select
        var ratingSelect = container.find("#star-rate");

        // Update rating
        ratingSelect.val(rating);

        // Remove active from others
        $(this).siblings("a").removeClass("active");

        // Add active to current
        $(this).addClass("active");

        // Container selected
        container.addClass("selected");

        // Submit the form
        form.find(".form-button").trigger("click");

        // Stop
        return false;

    });

    /**
     * Regular data pagination
     */
    $('body').on('click', '.pagination-button', function(){
        // Check the class
        if( $(this).hasClass('loading') === false ){
            // Get the data
            var data = jQuery.extend({}, $(this).data());

            // Check if there's a source set
            if( data.hasOwnProperty('source') === false ){
                // No source found
                throw 'No source for pagination';
            }

            // Get the source
            var source = data.source;

            // Remove the source
            delete data.source;

            // Check the page of the data
            if( data.hasOwnProperty('page') === false ){
                // Add the page to teh data
                data.page = 1;

                // Update the page
                $(this).data('page', 1);
            }

            // Add the ajax and pagination variable
            data._ajax = 1;
            data._pagination = 1;

            // Remove the error class
            $(this).removeClass('error');

            // Add the loading class
            $(this).addClass('loading');

            // Send the request
            $.ajax({
                url      : source,
                data     : data,
                dataType : 'json',
                type     : 'POST',
                success  : function(response){
                    // Check the response
                    if( response.error ){
                        // There was an error
                        $(this).addClass('error');

                        // Check if there's a results text
                        if( $(this).find('.results-text').length === 0 ){
                            // Add a results text
                            $(this).append('<p class="results-text"></p>');
                        }

                        // Add the response
                        $(this).find('.results-text').text(response.response);
                    }
                    else{
                        // Get the response
                        response = response.response;

                        // more handler
                        if( response.hasOwnProperty('more') && !response.more ) {
                            // Remove the source
                            $(this).remove();
                        }

                        // Check if there's a handler set
                        if( response.hasOwnProperty('handler') ){
                            // Get the handler
                            var handler = response.handler.charAt(0).toUpperCase() + response.handler.substr(1) + 'Handler';

                            // Check if the handler exists
                            if( typeof(window[handler]) === 'function' ){
                                // Create the handler
                                new window[handler](jQuery, this, response);
                            }
                        }
                        else{

                            // Use the default handler
                            new Handler(jQuery, this, response);
                        }
                    }

                    // Remove the loading class
                    $(this).removeClass('loading');

                    if( !response.hasOwnProperty('handler') || ( response.handler !== "pagination" && response.handler !== "post" ) ){
                        // Update the page
                        $(this).data('page', $(this).data('page') + 1);
                    }

                }.bind(this),
                error    : function(){
                    // Remove the loading class
                    $(this).removeClass('loading');

                    // There was an error
                    $(this).addClass('error');

                    // Check if there's a results text
                    if( $(this).find('.results-text').length === 0 ){
                        // Add a results text
                        $(this).append('<p class="results-text"></p>');
                    }

                    // Add the response
                    $(this).find('.results-text').text('Could not send the request. Please try again.');
                }.bind(this)
            });
        }
    });

    /**
     * Close Lightbox
     * @param lightbox
     */
    window.closeLightbox = function(lightbox){

        // Hide the lightbox and unfix the body
        $(lightbox).removeClass('show');
        $('body').removeClass('fixed');
        // Remove the event handler
        $(window).unbind('keydown');

        // so js should be used just to set the display block and none
        setTimeout(function () {
          $(lightbox).css('display','none');
        }, 400)
    }

    /**
     * Open Lightbox
     * @param lightbox
     */
    window.openLightbox  = function(lightbox){


        // Save the lightbox
        lightbox = $(lightbox);
        // so js should be used just to set the display block and none and classes
        lightbox.css('display','block');
        // no need to set height of the lightbox container with js
        // $(lightbox).css("height", $(document).height());

        // Get the data
        var data = $(this).data();

        // Loop through the data
        for( var id in data ){
            // Check the id
            if( id === 'lightbox' ){
                // Next
                continue;
            }

            // Get the identifier
            var identifier = id.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase();

            // If its only a link
            if( identifier === 'href' ) {
                lightbox.find("a.button").attr("href", data[id]);
            }

            // Find the element
            var element = lightbox.find('#' + identifier);

            // Find the element
            if( element.length > 0 ){
                // Set the value
                element.val(data[id]);
            }
        }

        // Add the event listener to the lightbox
        $(window).on('keydown', function(event){
            // Check the keycode
            if(event.keyCode == 27 ){
                // Close the lightbox
                closeLightbox(lightbox);
            }
        });

        // Show the lightbox and fix the body

        setTimeout(function () {

          lightbox.addClass('show');
        }, 10);
        if($(lightbox).find(".lightbox-image-container").length) {
            $('body').addClass('fixed');
        }

        // position the lightbox in the middle of the screen
        var lightboxContent = $(lightbox).find(".lightbox-content");
        var lightboxImage = $(lightbox).find(".lightbox-image-container");




        // login and warning/etc. are positioned absolute, relative to the document
        // placement of lightbox content within it's container does not need to be set
        // $(lightboxContent).css("top", ( ( ( window.innerHeight / 4 ) ) + $("body").scrollTop() ) /*- ( ($( lightboxContent ).height() / 4) * 3)*/);
        // I would assume that this is not necessary but I can't be sure atm
        // image lightboxes are positioned absolute, relative to the actual lightbox element
        $(lightboxImage).css("top", ( window.innerHeight / 2 ) - ( $( lightboxContent ).height() / 2) );
    }

    /**
     * Close Lightbox trigger
     * @param trigger
     */
    window.closeTrigger = function(trigger){
        // Check the trigger
        if( trigger.dataset.trigger ){
            // Clear the class
            $(trigger).removeClass('active');
            // Get the trigger
            trigger = $('#' + trigger.dataset.trigger);
            trigger.css('hidden')

            // Slide up
            trigger.slideUp(400);
        }
    }

    /**
     * Lightbox Event Handlers
     */
    $("body").on('click', '.lightbox-trigger', function(){
        openLightbox.call(this, $('#' + $(this).data('lightbox')).get(0));
    });

    $('body').on('click', ".lightbox .overlay", function(){
        closeLightbox($(this).closest('.lightbox'));
    });

    $('body').on('touchstart', ".lightbox .overlay", function(){
        closeLightbox($(this).closest('.lightbox'));
    });

    $("body").on('click', '.close-lightbox', function(){
        closeLightbox($(this).closest('.lightbox.show').get(0));
    });

    // Smooth scroll
    $(function() {
        $('a[href*="#"]:not([href="#"])').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                if (target.length) {
                    $('html, body').animate({
                        scrollTop: target.offset().top
                    }, 1000);
                    return false;
                }
            }
        });
    });

    $(".lightbox.activate").each(function(){
      openLightbox(this);
    });
});
