jQuery(document).ready(function($){

    /**
     * This function changes the time to local on a specific element
     * @param element
     */
    window.localeTime = function(element) {

        // Get the datetime
        var postDate = element.attr("datetime") ? element.attr("datetime") : element.attr("title");

        // Get the local date object
        var date = locale(postDate);

        // Update the values
        element.attr("datetime", date);
        element.attr("title", date);

        // Update word time
        element.timeago("update", date);

    }

    // Every time instance
    $("time").each(function() {
        localeTime($(this));
    })

    /**
     * Converts UTC to local time
     * @param timestamp
     * @returns {Date}
     */
    function locale( timestamp ) {

        // Get the date
        var newDate = getDateTime(timestamp);

        // Set Time to new local
        newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());

        // Return
        return newDate;

    }

    /**
     * Returns the correct date object using timestamp
     * @param timestamp
     * @returns {*}
     */
    function getDateTime( timestamp ) {

        var newDate = parseDate(timestamp);
        if (newDate instanceof Date) {
            return newDate;
        } else if (typeof newDate === "string") {
            return parseDate(newDate);
        } else if (typeof newDate === "number") {
            return new Date(newDate);
        } else {
            return parseDate(newDate);
        }

    }

    /**
     * Returns parsed date object using filters
     * @param iso8601
     * @returns {Date}
     */
    function parseDate( iso8601 ) {

        var s = $.trim(iso8601);
        s = s.replace(/\.\d+/,""); // remove milliseconds
        s = s.replace(/-/,"/").replace(/-/,"/");
        s = s.replace(/T/," ").replace(/Z/," UTC");
        s = s.replace(/([\+\-]\d\d)\:?(\d\d)/," $1$2"); // -04:00 -> -0400
        s = s.replace(/([\+\-]\d\d)$/," $100"); // +09 -> +0900
        return new Date(s);

    }

});