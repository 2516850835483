let oppdiv = document.querySelector('#opps'),
    repdiv = document.querySelector('#manage-rep'),
    addiv = document.querySelector('#manage-adverts')

if (oppdiv) {
  let selectBox = $("select.custom-select")

    selectBox.on("change", filterCards)
}

if(repdiv) {
    $("select.custom-select").on("change", filterCards);
}

if(addiv) {
  $("select.custom-select").on("change", filterCards);
}

function filterCards(e) {
  let cards = $('.op-card'),
      value = e.target.value.toLowerCase(),
      hiddenCards = $('.card-wrapper.hide')

  for (let card of hiddenCards) {
    card.classList.remove('hide')
  }

  for (let card of cards) {
    if ( !card.classList.contains(value) && value !== 'all') {
      card.parentNode.classList.add('hide')
    }
  }

}