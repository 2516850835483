$(function(){

    var profileEl = document.getElementById('basicProfile');
    if( $("#basicProfile").length > 0 ) {
        var basicProfile =new Croppie(profileEl, {
            enableExif: true,
            viewport: {
                width: 180,
                height: 180,
                type: 'circle',
            },
            boundary: {
                width: 300,
                height: 300
            }
        });
    }

    var statusBox = $("#profile").find(".profile-image-container .image-status");

    $('body').on('click', "#photo-cropper-lightbox .overlay", function(){
        // Hide the lightbox
        var lightbox = $(this).closest('.lightbox');
        closeLightbox( lightbox );
        // Remove the active button
        lightbox.find('.button.active').removeClass("active");
        // Update the status
        statusBox.removeClass("error uploading failed").text('');
    });
    $('body').on('touchstart', "#photo-cropper-lightbox .overlay", function(){
        // Hide the lightbox
        var lightbox = $(this).closest('.lightbox');
        closeLightbox( lightbox );
        // Remove the active button
        lightbox.find('.button.active').removeClass("active");
        // Update the status
        statusBox.removeClass("error uploading failed").text('');
    });
    $("body").on('click', '#photo-cropper-lightbox .close-lightbox', function(){
        var lightbox = $(this).closest('.lightbox.show').get(0);
        closeLightbox( lightbox );
        // Remove the active button
        $(this).closest('.lightbox').find('.button.active').removeClass("active");
        // Update the status
        statusBox.removeClass("error uploading failed").text('');
    });

    /**
     * Read and Preview
     * @param handle
     * @param target
     */
    function readAndPreview(handle, target, trigger){

        // Get the file
        var file = handle.get(0).files[0];

        var container = target.parent();

        var status = container.find(".image-status");

        status.removeClass("error failed").text('');
        status.addClass("uploading").text('Loading image...');

        // Check file size - 8MB
        if( !__checkFileSize(file) ) {

            // Display the error
            status.addClass("error");
            var msg = "Image is too big. Allowed size is 8MB";
            status.text(msg);

            // Add the error class
            status.addClass('failed');

            // The image was deleted
            status.removeClass('uploading');

            // Show notification
            addNotification(msg, 'error');

            // Add the class
            status.removeClass('uploaded');

            return;
        }

        // only images allowed
        if ( !/\.(jpe?g|png)$/i.test(file.name) ) {

            // Display the error
            status.addClass("error");
            var msg = "Please select an image.";
            status.text(msg);

            // Add the error class
            status.addClass('failed');

            // The image was deleted
            status.removeClass('uploading');

            // Show notification
            addNotification(msg, 'error');

            // Add the class
            status.removeClass('uploaded');

            return;

        }

        // Activate Croppie
        if( !basicProfile instanceof Croppie ) {
            basicProfile = new Croppie(profileEl, {
                enableExif: true,
                viewport: {
                    width: 180,
                    height: 180,
                    type: 'circle',
                },
                boundary: {
                    width: 300,
                    height: 300
                }
            });
        }

        // Save the source button instance
        var button = this;

        // Create a file reader
        var reader = new FileReader();

        reader.addEventListener("load", function () {

            // Add image url
            basicProfile.bind({
                url: reader.result,
                orientation: 4
            });

            // Activate the lightbox
            var lightbox = $("#photo-cropper-lightbox");
            openLightbox.call(this, lightbox);

            // Get the use button
            var savePhoto = lightbox.find(".usePhoto");
            // Bind onClick
            savePhoto.unbind('click').on('click', profilePhotoUpload.bind(this, savePhoto, button, file, handle, target, trigger));

        }, false);

        // Read the file
        reader.readAsDataURL(file);

        // Remove selected file
        handle[0].value = '';

        return;
    }

    /**
     * Upload the cropped file
     * @param button
     * @param file
     * @param handle
     * @param target
     */
    function profilePhotoUpload(element, button, file, handle, target, trigger) {

        // Button is active
        if( element.hasClass("active") ) {
            return;
        }

        // Activate spinner
        element.addClass("active");

        //var size = 'original';
        var size = {
            width: 400,
            height: 400
        };

        // Get the Cropped blob
        basicProfile.result('canvas', 'viewport', 'png', 0.9, 'false').then(function(dataUrl) {

            // Check the size
            var head = 'data:image/png;base64,';
            var imgFileSize = Math.round((dataUrl.length - head.length)*3/4) ;

            if( imgFileSize > 8388608 ) {

                var container = target.parent();

                var status = container.find(".image-status");

                // Display the error
                status.addClass("error");
                var msg = "This image is too big. Please try a different image or scale it down a little.";
                status.text("This image is too big. Please try a different image.");

                // Add the error class
                status.addClass('failed');

                // The image was deleted
                status.removeClass('uploading');

                // Show notification
                addNotification(msg, 'error');

                // Add the class
                status.removeClass('uploaded');

                // Deactivate the button
                element.removeClass("active");

                return;

            }

            // Get the container
            var container = target.parent();

            // Get status element
            var status = container.find(".image-status");

            // Status
            status.removeClass("error failed").text('');
            status.addClass("uploading").text('Uploading...');

            // Create the form
            var form_data = new FormData();

            // Add the file
            form_data.append('filedata', dataUrl);
            form_data.append('blob', true);
            form_data.append('name', file.name);

            // Check the icon
            var icon = button.dataset.icon;

            // Send the ajax request
            $.ajax({
                url         : button.dataset.upload,
                data        : form_data,
                dataType    : 'json',
                type        : 'POST',
                cache       : false,
                processData : false,
                contentType : false,
                success     : function(target, handle, trigger, dataUrl, response) {

                    // Check the response
                    if ( response.error ) {
                        // Could not upload
                        addNotification(response.response, "error");
                        if (icon != "undefined") {
                            target.html('<i class="material-icons icon">' + icon + '</i>');
                        }

                    } else {

                        // No error found
                        // Update the value
                        button.value = response.response;

                        if (icon != "undefined") {
                            target.html('');
                        }

                        // Update for img
                        if( target.is('img') ) {
                            target.attr('src', dataUrl);
                        } else {
                            target.css('background-image', 'url(' + dataUrl + ')');
                        }

                        status.removeClass("uploading").text("");

                        // Target now has image
                        target.addClass('has-image');

                        // Remove the handle
                        handle.remove();

                        // Show Remove
                        trigger.find(".trigger-text.add").hide();
                        trigger.find(".trigger-text.remove").show();

                    }

                    // Update the text
                    status.removeClass("uploading").text("");

                    // Remove the spinnser
                    element.removeClass("active");

                    // Close the lightbox
                    var lightbox = $("#photo-cropper-lightbox.show");
                    closeLightbox(lightbox);

                }.bind(this, target, handle, trigger, dataUrl),
                error: function(msg) {
                    // Could not upload
                    // addNotification("The cropped image exceeds the size limit.", "error");
                    addNotification(msg, "error");
                    if (icon != "undefined") {
                        target.html('<i class="material-icons icon">' + icon + '</i>');
                    }
                    element.removeClass("active");
                }.bind(this, target, handle, trigger, dataUrl)
            });

        });

    }

    /**
     * Add Crop tool to the profile picture
     */
    $('#profile input.profile-picture').each(function(){
        // Get the trigger, target, upload, and delete
        var trigger = this.dataset.trigger;
        var target = this.dataset.target;
        var upload = this.dataset.upload;
        var del = this.dataset.delete;
        var action = this.dataset.action;
        var icon = this.dataset.icon;

        // Check the trigger
        if( trigger === undefined || trigger.length === 0 ){
            // Missing trigger
            throw 'Form image is missing trigger';
        }

        // Check the target
        if( target === undefined || target.length === 0 ){
            // Missing target
            throw 'Profile Form image is missing target';
        }

        // Check the upload
        if( upload === undefined || upload.length === 0 ){
            // Missing target
            throw 'Profile Form image is missing upload';
        }

        // Check the delete
        if( del === undefined || del.length === 0 ){
            // Missing target
            throw 'Profile Form image is missing delete';
        }

        // Check the action
        if( action === undefined || action.length === 0 ){
            // Missing target
            throw 'Profile Form image is missing the action';
        }

        // Get the trigger and target
        trigger = $(trigger);
        target = $(target);

        // Add the trigger class
        trigger.addClass('trigger');

        // Show the trigger text
        if( target.hasClass('has-image') ) {
            // Show delete
            trigger.find(".trigger-text.add").hide();
            trigger.find(".trigger-text.remove").show();
        } else {
            // Show Add
            trigger.find(".trigger-text.remove").hide();
            trigger.find(".trigger-text.add").show();
        }

        // Set the event handler
        trigger.on('click', function(){
            // Get the handle
            var handle = $('#' + this.dataset.id + '-handle');

            // Get the target
            var target = $(this.dataset.target);

            // Check if the target has an image
            if( target.hasClass('has-image') ){
                // // Delete the image
                // $.ajax({
                //     url      : this.dataset.delete,
                //     data     : {
                //         action: this.dataset.action,
                //         id: this.value
                //     },
                //     dataType : 'json',
                //     type     : 'POST',
                //     success: function(target, response){
                //         // Check the response
                //         if( response.error ){
                //             // Could not delete
                //             addNotification(response.response, "error");
                //             if( icon != "undefined" ) {
                //                 target.html('<i class="material-icons icon">'+icon+'</i>');
                //             }
                //         } else{
                //             // Clear the input
                //             this.value = '';
                //
                //             // Clear the target
                //             target.removeClass('has-image');
                //
                //             // Clear the background
                //             target.css('background-image', 'none');
                //
                //             if( icon != "undefined" ) {
                //                 target.html('<i class="material-icons icon">'+icon+'</i>');
                //             }
                //
                //             // Show Add
                //             trigger.find(".trigger-text.remove").hide();
                //             trigger.find(".trigger-text.add").show();
                //
                //             // Removed
                //             addNotification("Image has been removed",  "success");
                //         }
                //     }.bind(this, target)
                // });

                // Clear the input
                this.value = '';

                // Clear the target
                target.removeClass('has-image');

                if( target.is('img') ) {
                    target.attr("src", "data:image/gif;base64,R0lGODlhAQABAIAAAHd3dwAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==");
                } else {
                    target.css('background-image', 'none');
                }

                // Show Add
                trigger.find(".trigger-text.remove").hide();
                trigger.find(".trigger-text.add").show();

                // Removed
                addNotification("Image has been removed",  "success");
            }
            else{
                // Check the input
                if( handle.length === 0 ){
                    // Get the parent
                    var parent = $(this.parentElement);

                    // Create a new input
                    handle = $.parseHTML('<input id="' + this.dataset.id + '-handle" type="file">');

                    // Set the handle
                    handle = $(handle);

                    // Set the event handler
                    handle.on('change', readAndPreview.bind(this, handle, target, trigger));

                    // Append the input
                    parent.append(handle);
                }

                // Trigger the click
                handle.trigger('click');
            }
        }.bind(this));
    });

});