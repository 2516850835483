jQuery(document).ready(function($){

    /**
     * Checks whether file size is ok to upload - 8MB
     * @param size
     * @returns {boolean}
     */
    window.__checkFileSize = function(file) {
        if (file.size > 8388608 || file.fileSize > 8388608) {
            return false;
        }
        // Default
        return true;
    }

    /**
     * Profile page image upload
     * @param handle
     * @param target
     */
    function profileImageUpload(handle, target){
        // Get the files
        var file = handle.get(0).files[0];

        var container = target.parent();

        var status = container.find(".image-status");

        status.removeClass("error failed").text('');

        status.addClass("uploading").text('Uploading...');

        // Check file size - 8MB
        if( !__checkFileSize(file) ) {

            // Display the error
            status.addClass("error");
            var msg = "Image is too big. Allowed size is 8MB";
            status.text(msg);

            // Add the error class
            status.addClass('failed');

            // The image was deleted
            status.removeClass('uploading');

            // Show notification
            addNotification(msg, 'error');

            // Add the class
            status.removeClass('uploaded');

            return;
        }

        // Create the form
        var form_data = new FormData();

        // Add the file
        form_data.append('file', file);

        // Check the icon
        var icon = this.dataset.icon;

        // Send the ajax request
        $.ajax({
            url         : this.dataset.upload,
            data        : form_data,
            dataType    : 'json',
            type        : 'POST',
            cache       : false,
            processData : false,
            contentType : false,
            success     : function(handle, target, file, response){

                // Check the response
                if( response.error ){

                    // Could not upload
                    addNotification(response.response, "error");
                    if( icon != "undefined" ) {
                        target.html('<i class="material-icons icon">'+icon+'</i>');
                    }

                } else{
                    // No error found
                    // Update the value
                    this.value =  response.response;

                    if( icon != "undefined" ) {
                        target.html('');
                    }

                    var options = {
                        canvas: true,
                    };

                    // Use the "JavaScript Load Image" functionality to parse the file data
                    loadImage.parseMetaData(file, function(data) {

                        status.addClass("uploading").text('Enhancing...');

                        // Get the correct orientation setting from the EXIF Data
                        if (data.exif) {
                            options.orientation = data.exif.get('Orientation');
                        }

                        // Load the image from disk and inject it into the DOM with the correct orientation
                        loadImage(
                            file,
                            function(canvas) {
                                var imgDataURL = canvas.toDataURL();
                                target.css('background-image', 'url(' + imgDataURL + ')');
                                status.removeClass("uploading").text("");
                            },
                            options
                        );
                    });

                    // Target now has image
                    target.addClass('has-image');

                    // Remove the handle
                    handle.remove();
                }

                status.removeClass("uploading").text("");

            }.bind(this, handle, target, file)
        });
    }

    // Only for profile page
    $('#profile input.profile-form-image').each(function(){
        // Get the trigger, target, upload, and delete
        var trigger = this.dataset.trigger;
        var target = this.dataset.target;
        var upload = this.dataset.upload;
        var del = this.dataset.delete;
        var action = this.dataset.action;
        var icon = this.dataset.icon;

        // Check the trigger
        if( trigger === undefined || trigger.length === 0 ){
            // Missing trigger
            throw 'Form image is missing trigger';
        }

        // Check the target
        if( target === undefined || target.length === 0 ){
            // Missing target
            throw 'Profile Form image is missing target';
        }

        // Check the upload
        if( upload === undefined || upload.length === 0 ){
            // Missing target
            throw 'Profile Form image is missing upload';
        }

        // Check the delete
        if( del === undefined || del.length === 0 ){
            // Missing target
            throw 'Profile Form image is missing delete';
        }

        // Check the action
        if( action === undefined || action.length === 0 ){
            // Missing target
            throw 'Profile Form image is missing the action';
        }

        // Get the trigger and target
        trigger = $(trigger);
        target = $(target);

        // Add the trigger class
        trigger.addClass('trigger');

        // Set the event handler
        trigger.on('click', function(){
            // Get the handle
            var handle = $('#' + this.dataset.id + '-handle');

            // Get the target
            var target = $(this.dataset.target);

            // Check if the target has an image
            if( target.hasClass('has-image') ){
                // Delete the image
                $.ajax({
                    url      : this.dataset.delete,
                    data     : {
                        action: this.dataset.action,
                        id: this.value
                    },
                    dataType : 'json',
                    type     : 'POST',
                    success: function(target, response){
                        // Check the response
                        if( response.error ){
                            // Could not delete
                            addNotification(response.response, "error");
                            if( icon != "undefined" ) {
                                target.html('<i class="material-icons icon">'+icon+'</i>');
                            }
                        } else{
                            // Clear the input
                            this.value = '';

                            // Clear the target
                            target.removeClass('has-image');

                            // Clear the background
                            target.css('background-image', 'none');

                            if( icon != "undefined" ) {
                                target.html('<i class="material-icons icon">'+icon+'</i>');
                            }

                            // Removed
                            addNotification("Image has been removed",  "success");
                        }
                    }.bind(this, target)
                });
            }
            else{
                // Check the input
                if( handle.length === 0 ){
                    // Get the parent
                    var parent = $(this.parentElement);

                    // Create a new input
                    handle = $.parseHTML('<input id="' + this.dataset.id + '-handle" type="file">');

                    // Set the handle
                    handle = $(handle);

                    // Set the event handler
                    handle.on('change', profileImageUpload.bind(this, handle, target));

                    // Append the input
                    parent.append(handle);
                }

                // Trigger the click
                handle.trigger('click');
            }
        }.bind(this));
    });

});
