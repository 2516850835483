jQuery(document).ready(function($){
	/**
	 * Closes a notification
	 * The notification should be binded to the function
	 */
	function closeNotification(){
		// Hide the class
		this.addClass('hide');

		// Set the timeout
		setTimeout(function(){
			// Slide notification
			this.slideUp(200, function(){
				this.remove();
			}.bind(this));
		}.bind(this), 200);
	}

	/**
	 * Adds a new notification
	 * @param message The message to add
	 * @param type The type of the message
	 * @param timeout The timeout of the message, defaults to 2000
	 */
	window.addNotification = function(message, type, timeout){
		// Get the container
		var container = $('#system-notifications');

		// Get the notifications
		var notifications = container.find('div.notification');

		// The notification
		var notification = null;

		// Check the container length
		if( notifications.length > 5 ){
			// The length
			var length = notifications.length - 5;

			// Loop through the notifications
			for( var i = 0; i < length; i++ ){
				// Get the notification
				notification = notifications.get(i);

				// Remove the notification
				closeNotification.call($(notification));
			}
		}

		// Create the notification
		notification = $($.parseHTML('<div class="notification"><p>' + message + '</p><div class="close-notification"><i class="material-icons">close</i></div></div>'));

		// Check the type
		if( type !== undefined ){
			// Add the type
			notification.addClass(type);
		}

		// Find the close icon
		notification.find('div.close-notification').on('click', closeNotification.bind(notification));

		// Add the notification to the container
		container.append(notification);

		// Set the timeout
		setTimeout(function(){
			// Show the container
			this.addClass('show');
		}.bind(notification), 100);

		// Get the timeout
		if( timeout === undefined ){
			// Set the default timeout
			timeout = 2000;
		}

		// Set the timeout
		var timeout_func = setTimeout(closeNotification.bind(notification), timeout);

		// Save the timeout
		notification.data('timeout', timeout_func);
	}
});