$(function () {

  let html = '<div class="popover" role="tooltip">'+
                '<div class="arrow"></div>'+
                '<h3 class="popover-header"></h3>'+
                '<div class="popover-body"></div>'+
              '</div>'

  $('[data-toggle="notification-popover"]').popover({
    // template: html
  })
})
