(function(){
    /**
     * Invite Handler
     * Handles invitation responses
     * @param response
     * @constructor
     */
    window.InviteHandler = function(jquery, source, response){
        // Initialize the handler
        Handler.call(this, jquery, source, response);
    }

    // Set the inheritance
    window.InviteHandler.prototype   = Object.create(window.Handler.prototype);
    window.InviteHandler.constructor = InviteHandler;

    /**
     * Initializes the handler
     * @param response The response from the handler
     */
    InviteHandler.prototype.initialize = function(response){

        // Add the actions allowed
        this._allowed.push('sent');

        // Prepare the response
        this.prepare(response);

        // Run the response
        this.run();
    }

    /**
     * Invitation is sent
     */
    InviteHandler.prototype.sent = function(){

        var message = "Your invitations has been sent.";

        if(this._data.hasOwnProperty('message')) {
            message = this._data.message;
        }

        // Clear the form
        var form = this._source.closest(".form");
        form.trigger('reset');

        // Show the notification
        addNotification( message, 'success');

    }

}());