/**
 * Action contrustor
 * @param params The parameters of the action
 * @constructor
 */
function Action(type, params){
	// Set the variables
	this.type    = type;
	this.next    = null;
	this.options = {};
	this.action  = {};

	// Check the type of parameters
	if( typeof(params) === 'object' ){
		// Loop through the object
		for( var key in params ){
			// Check the key
			if( key[0] === '_' ){
				// Add the options
				this.options[key.slice(1)] = params[key];
			}
			else{
				// Add it to the actions
				this.action[key] = params[key];
			}
		}
	}
	else if( typeof(params) === 'string' ){
		// Set the options
		this.action = params;
	}

	// Return the action reference
	return this;
}