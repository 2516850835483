jQuery(document).ready(function($) {

    if ($('.datepicker').length != 0) {
        $.each($("input.datepicker"), function(a, b){

            var $_date = $(this).pickadate({
                format: 'mmmm dd, yyyy',
                selectYears: 40
            });

            var input = this;
            var max = input.dataset.max;
            if(max) {
                var dobPicker = $_date.pickadate('picker');
                dobPicker.set("max", max);
            }
            var min = input.dataset.min;
            if(min) {
                var dobPicker = $_date.pickadate('picker');
                dobPicker.set("min", min);
            }
        });
    }

    $('body').on('click', '[id*="_present"]', changeDate)
    $('body').on('click', '.datepicker.end-date', uncheckBox)
});


function changeDate(e) {
  let el = $(this),
      parent = $(this).parents('.middle'),
      text_input = parent.find('.datepicker.end-date')

  el.is(':checked')? text_input.val('Present') : text_input.val('')
  console.log(el);
}
function uncheckBox(e) {
  let el = $(this),
      parent = $(this).parents('.middle'),
      checkbox = parent.find('[id*="_present"]')

  checkbox.prop('checked', false);

}
