let hamburger = document.querySelector('.hamburger'),
    body = $('body')

// NOTE: this is ham
hamburger.addEventListener('click', add_active_class)
body.on('click', '[data-lightbox="login-lightbox"]', remove_active_class)

function add_active_class() {

  hamburger.classList.toggle('is-active')
}

function remove_active_class(e) {
  $(window).width() < 1113 ? hamburger.classList.remove('is-active') : e.stopPropagation()
}
