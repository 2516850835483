/**
 * Cookie popup handler
 */
(function($) {

$.fn.ckPopup = (function() {

    var _self = this;

    // Params
    _self.params = {
        popupText : 'We use cookies to collect information to help us personalize your experience and improve the functionality and performance of our site. By continuing to use our site [without first changing your browser setting], you consent to our use of cookies. For more information see our cookies policy.',
        agreementExpiresInDays : 30,
    };

    // Variables to be used
    _self.vars = {
        HTML_MARKUP : null,
        COOKIE_NAME : 'ftf-cookie-consent'
    }

    /**
     * Creates the markup
     * @returns {string}
     */
    var createMarkup = function() {
        var html = '<div class="ckpopup-block"><div class="ckpopup-container">' +
        '<div class="ckpopup-text">' + _self.params.popupText + ' <a href="#" class="ckpopup-button">Continue</a></div>' +
        '<a href="#" class="ckpopup-close"><i class="fas fa-times"></i></a>' +
        '</div></div>';
        return html;
    };

    /**
     * Saves the content to cookie
     * @param consent
     */
    var setUserAcceptsCookies = function(consent) {
        var d = new Date();
        var expiresInDays = _self.params.agreementExpiresInDays * 24 * 60 * 60 * 1000;
        d.setTime( d.getTime() + expiresInDays );
        var expires = "expires=" + d.toGMTString();
        document.cookie = _self.vars.COOKIE_NAME + '=' + consent + "; " + expires + ";path=/";
    };

    /**
     * Checks if cookie already exists
     * @returns {boolean}
     */
    var userAlreadyAcceptedCookies = function() {
        var userAcceptedCookies = false;
        var cookies = document.cookie.split(";");
        for (var i = 0; i < cookies.length; i++) {
            var c = cookies[i].trim();
            if (c.indexOf(_self.vars.COOKIE_NAME) == 0) {
                userAcceptedCookies = c.substring(_self.vars.COOKIE_NAME.length + 1, c.length);
            }
        }
        return userAcceptedCookies;
    };

    /**
     * Hide the popup container
     */
    var hideContainer = function() {
        $('.ckpopup-container').animate({
            opacity: 0,
            height: 0
        }, 200, function() {
            $('.ckpopup-container').hide(0);
        });
    };

    /**
     * Initialize
     */
    return {

        init : function() {

            // Check if user has already accepted the cookie policy
            if (userAlreadyAcceptedCookies()) {
                return;
            }

            // Build Markup
            _self.vars.HTML_MARKUP = createMarkup();

            // Append html markup
            $('body').append(_self.vars.HTML_MARKUP);

            // Bind elements
            $('.ckpopup-button').click(function() {
                setUserAcceptsCookies(true);
                hideContainer();
                return false;
            });

            // Close button
            $('.ckpopup-close').click(function() {
                setUserAcceptsCookies(true);
                hideContainer();
                return false;
            });

            // Show the container
            $('.ckpopup-container').show();

        }

    }

});

$(document).ready( function() {
    $(document).ckPopup().init();
});

}(jQuery));