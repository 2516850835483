(function(){
    /**
     * Opportunity Handler
     * Handles opportunities requests response
     * @param response
     * @constructor
     */
    window.OpportunityHandler = function(jquery, source, response){
        // Initialize the handler
        Handler.call(this, jquery, source, response);
    }

    // Set the inheritance
    window.OpportunityHandler.prototype   = Object.create(window.Handler.prototype);
    window.OpportunityHandler.constructor = OpportunityHandler;

    /**
     * Initializes the handler
     * @param response The response from the handler
     */
    OpportunityHandler.prototype.initialize = function(response){

        // Add the actions allowed
        this._allowed.push('favourite');
        this._allowed.push('unfavourite');
        this._allowed.push('price');

        // Prepare the response
        this.prepare(response);

        // Run the response
        this.run();
    }

    /**
     * Loads the prices html
     */
    OpportunityHandler.prototype.price = function(){
        // Get the data
        var html = this._data.html;
        // Get the container
        var container = this._jquery('#price-info');
        // Add
        container.html(html);
        // Show message if any
        if(this._data.hasOwnProperty('message')) {
            addNotification(this._data.message, 'success');
        }
    }

    /**
     * Opportunity is added to favourite list
     */
    OpportunityHandler.prototype.favourite = function(){

        // Build message
        var message = this._data.message;

        // Switch to unfavorite - TODO: simplify this later
        var container = this._source.closest(".action-controls")
        container.find(".favourite").addClass("hide");
        container.find(".unfavourite").removeClass("hide");

        // Show the notifications
        addNotification( message, 'success');
    }

    /**
     * Opportunity is removed from favourite list
     */
    OpportunityHandler.prototype.unfavourite = function(){

        // Build message
        var message = this._data.message;

        // Switch to favorite - TODO: simplify this later
        var container = this._source.closest(".action-controls");
        container.find(".unfavourite").addClass("hide");
        container.find(".favourite").removeClass("hide");

        // Show the notification
        addNotification( message, 'success');

    }

}());
