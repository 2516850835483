jQuery(document).ready(function($) {

    var form = $(".ajax-search").find(".form");

    // Trigger the search on page load
    var field = form.find("input#keyword");

    if( field.length > 0 && field.val() != '' ) {
        form.find(".search-button").trigger("click");
    }

    $('.form-pagination-button').on('click', function(){
        // Check the class
        if( $(this).hasClass('loading') === false ){

            // Get the form variables
            var form_data = form.serializeArray();

            // Create data object
            var data = {};
            $.each(form_data, function() {
                if (data[this.name] !== undefined) {
                    if (!data[this.name].push) {
                        data[this.name] = [data[this.name]];
                    }
                    data[this.name].push(this.value || '');
                } else {
                    data[this.name] = this.value || '';
                }
            });

            // Get the data
            var data = jQuery.extend(data, $(this).data());

            //Check if there's a source set
            if( data.hasOwnProperty('source') === false ) {
                // Not found
                throw 'No source for pagination';
            }

            // Get the source
            var source = data.source;

            // Remove the source
            delete data.source;

            // Check the page of the data
            if( data.hasOwnProperty('page') === false || $(this).data('page') == 0 ){
                // Add the page to the data
                data.page = 1;

                // Update the page
                $(this).data('page', 1);
            }

            // Add the ajax and pagination variable
            data._ajax = 1;
            data._pagination = 1;

            // Remove the error class
            $(this).removeClass('error');

            // Add the loading class
            $(this).addClass('loading');

            // Send the request
            $.ajax({
                url      : source,
                data     : data,
                dataType : 'json',
                type     : 'POST',
                success  : function(response){
                    // Check the response
                    if( response.error ){
                        // There was an error
                        $(this).addClass('error');

                        // Check if there's a results text
                        if( $(this).find('.results-text').length === 0 ){
                            // Add a results text
                            $(this).append('<p class="results-text"></p>');
                        }

                        // Add the response
                        $(this).find('.results-text').text(response.response);
                    }
                    else{
                        // Get the response
                        response = response.response;

                        // Check if there's a handler set
                        if( response.hasOwnProperty('handler') ){
                            // Get the handler
                            var handler = response.handler.charAt(0).toUpperCase() + response.handler.substr(1) + 'Handler';

                            // Check if the handler exists
                            if( typeof(window[handler]) === 'function' ){
                                // Create the handler
                                new window[handler](jQuery, this, response);
                            }
                        }
                        else{
                            // Use the default handler
                            new Handler(jQuery, this, response);
                        }
                    }

                    // Remove the loading class
                    $(this).removeClass('loading');

                }.bind(this),
                error    : function(){
                    // Remove the loading class
                    $(this).removeClass('loading');

                    // There was an error
                    $(this).addClass('error');

                    // Check if there's a results text
                    if( $(this).find('.results-text').length === 0 ){
                        // Add a results text
                        $(this).append('<p class="results-text"></p>');
                    }

                    // Add the response
                    $(this).find('.results-text').text('Could not send the request. Please try again.');
                }.bind(this)
            });
        }
    });

});