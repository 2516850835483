let notification = $('main .notification')

if (notification.length > 0) {


  showNotification(notification)
}



function showNotification(notification) {

  notification.addClass('show')

  setTimeout(function(){
    notification.removeClass('show')

  }, 7000);
}
