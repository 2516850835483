

let howitworks = document.querySelector('.howitworks')

if (howitworks) {
  (function ($) {
    
    // $('#howitworks-tabs a').on('click', function (e) {
    //   e.preventDefault()
    //   // $(this).tab('show')
    //   return false;
    // })
  }(jQuery))
}

