(function(){
	/**
	 * TODO: Check the progress bar on big files, i.e., large images
	 * _Uploader Constructor
	 * Creates an uploader object
	 * @param options The options to create the object with
	 * @returns {_Uploader}
	 * @private
	 */
	function _Uploader(container, settings){
		// Initialize the uploader
		this.uploader  = null;
		this.input     = null;
		this.list      = null;
		this.container = container;
		this.options   = {
			id     : '',
			label  : '',
			upload : '',
			delete : '',
		};

		// Loop through the properties in the settings
		for( var prop in this.options ){
			// Check the setting
			if( settings.hasOwnProperty(prop) === false && settings[prop] ){
				// Add the property to the options
				this.options[prop] = settings[prop];
			}
		}

		// Check if container has options in it
		var options = this.container.querySelector('.options');

		// Check the options
		if( options ){
			// Loop through the options
			for( var prop in this.options ){
				// Check the options
				if( options.dataset[prop] ){
					// Override the options
					this.options[prop] = options.dataset[prop];
				}
			}
		}

		// Initialize the uploader
		this.init();

		// Return the instance of the uploader
		return this;
	}

	/**
	 * Initializes the uploader
	 */
	_Uploader.prototype.init = function(){
		// Delete all the children from the container
		while( this.container.lastChild ){
			// Remove the last child
			this.container.removeChild(this.container.lastChild);
		}

		// Create the uploader
		var uploader = document.createElement('div');
		uploader.setAttribute('class', 'uploader');

		// Save the id
		uploader.dataset.uploader = this.options.id;

		// Set the uploader
		this.uploader = uploader;

		// Render the label
		this.createUploaderLabel();

		// Create the upload list
		this.createUploaderList();

		// Create the input field
		this.createUploaderInput();

		// Add the uploader
		this.container.appendChild(uploader);
	}

	_Uploader.prototype.createUploaderInput = function(){
		// Create the input
		this.input = __create('input', {
			'id'    : this.options.id + '-input',
			'type'  : 'file',
			'name'  : 'file',
			'class' : 'file-handle'
		});

		// Add the event listener
		this.input.addEventListener('change', function(){
			// Get the files
			var file_list = this.input.files;

			// Loop through the files
			for( var i = 0, length = file_list.length; i < length; i++ ){
                // Upload the file
                this.uploadFile(file_list[i]);
			}

			// Remove the input from the uploader and recreate it
			this.uploader.removeChild(this.input);
			this.createUploaderInput();
		}.bind(this));

		// Add the inputs
		this.uploader.appendChild(this.input);
	}

	_Uploader.prototype.createUploaderLabel = function(){
		// Create the container
		var container = __create('div', {
			class : 'label-container'
		});

		// Create the label
		var label = __create('label', {
			for   : this.options.id + '-input',
			class : 'label uploader-label'
		}, container);

		// Create an icon
		var icon = __create('icon', {
			class : 'material-icons icon',
		}, container);

		// Create the text
		var text = __create('p', {
			class : 'text'
		}, container);

		/**
		 * Set the dragover event handler
		 * Has to stop the event to become a valid drag and drop container
		 */
		label.addEventListener('dragover', function(event){
			// Prevent the event
			event.preventDefault();
		});

		/**
		 * Set the drag event event handler
		 * Adds file hover class
		 */
		label.addEventListener('dragenter', function(event){
			// Check the parent
			if( this.parentElement.classList.contains('file-hover') === false && event.dataTransfer.types[0] === 'Files' ){
				// Add the file class
				this.parentElement.classList.add('file-hover');

				// Prevent default
				event.preventDefault();
			}
		});

		/**
		 * Set the drag leave event handler
		 * Removes the file hover class
		 */
		label.addEventListener('dragleave', function(){
			// Remove the class
			this.parentElement.classList.remove('file-hover');
		});

		/**
		 * Set the drop event handler
		 * Uploads a file
		 */
		label.addEventListener('drop', function(event){
			// Prevent the default
			event.preventDefault();

			// Check if the parent has the file hover class
			if( event.target.parentElement.classList.contains('file-hover') ){
				// Loop through the files
				for( var i = 0, length = event.dataTransfer.files.length; i < length; i++ ){
					//console.log(event.dataTransfer.files[i]);
					// Upload the file
					this.uploadFile(event.dataTransfer.files[i]);
				}

				// Remove the class
				event.target.parentElement.classList.remove('file-hover');
			}
		}.bind(this));

		// Add the text
		text.appendChild(document.createTextNode(this.options.label))

		// Set the icon text
		icon.appendChild(document.createTextNode('file_upload'));

		// Add the label
		this.uploader.appendChild(container);
	}

	_Uploader.prototype.createUploaderList = function(){
		// Create the list
		this.list = document.createElement('div');

		// Set the lists attributes
		this.list.setAttribute('class', 'uploader-list');

		// Add the list
		this.uploader.appendChild(this.list);
	}

	_Uploader.prototype.uploadFile = function(file){
		// Add a new file instance
		var container = this.addFile(file);

        // Check file size - 8MB
        if( !__checkFileSize(file) ) {

            // Find the status
            var status = container.querySelector('.file-status');

            // Display the error message
            status.classList.add('error');
            var msg = "Image is too big. Allowed size is 8MB";
            status.lastChild.nodeValue = msg;

            // Add the error class
            container.classList.add('failed');

            // The image was deleted
            container.classList.remove('uploading');

            // Show notification
            addNotification(msg, 'error');

            // Set a timeout
            setTimeout(function(){
                // Add the deleted class
                container.classList.remove('uploaded');
                container.classList.add('hide');
            }.bind(container), 850);

            // Set a new timeout
            setTimeout(function(){
                // Remove the file
                container.parentElement.removeChild(container);
            }.bind(container), 850);

            return;
        }

		// Create a form data
		var form_data = new FormData();

		// Add the file
		form_data.append('file', file);

		// Create a request
		var xhr = new XMLHttpRequest();
		xhr.open('POST', this.options.upload, true);
		xhr.responseType = 'json';

		// Set the event handler for the progress bar
		xhr.upload.addEventListener('progress', function(event){
			// Get the loading bar
			var loading_bar = this.querySelector('.info-container .loading-bar .bar');

			// Calculate the percentage
			var percentage = (event.loaded / event.total) * 100;

			// Create the translate
			var translate = 'translateX(' + (100 - percentage) * -1 + '%)';

			// Translate the percentage
			loading_bar.style.webkitTransform = translate;
			loading_bar.style.mozTransform    = translate;
			loading_bar.style.msTransform     = translate;
			loading_bar.style.transform       = translate;
		}.bind(container));

		// Set the event handler when the file was loaded
		xhr.addEventListener('load', function(container, event){
			// Get the request
			var request = event.target;

			// Remove the uploading class
			container.classList.remove('uploading');

			// Find the status
			var status = container.querySelector('.file-status');

			try{
				// Check the request status
				if( request.status === 200 ){
					// Check the response
					if( request.response == null ){
						// Could not parse the request
						//throw 'Could not parse the response';
                        throw 'File could not be uploaded';
					}

					// Get the response
					var response = request.response;

					// Check if there was an error with the response
					if( response.error ){
						// There was an error uploading the file
						throw response.response;
					}
					else{
						// File was uploaded
						// Get the media id
						var media_id = response.response;

						// Add the uploaded class
						container.classList.add('uploaded');

						// Update the status
						status.classList.add('success');
						status.lastChild.nodeValue = 'Uploaded';

						// Update the container
						var identifier = __create('div', {
							'class' : 'media-id'
						}, container);

						// Add the id to the identifier
						identifier.dataset.id = media_id;

						// Create a new hidden input
						var input = __create('input', {
							type  : 'hidden',
							name  : this.uploader.dataset.uploader + '[]',
							value : media_id,
							class : 'uploader-value'
						}, container);

						// Create the action
						var icon = __create('i', { 'class' : 'material-icons action remove' }, container.querySelector('.actions-container'));
						icon.appendChild(document.createTextNode('delete'));

						// Add the icon event handler
						icon.addEventListener('click', function(container){
							// Find the media id
							var media_id = container.querySelector('.media-id');

							// Check the media
							if( media_id ){
								// Get the id
								media_id = media_id.dataset.id;

								// Create the form
								var _delete_form = new FormData();
								_delete_form.append('action', 'delete');
								_delete_form.append('id', media_id);

								// Send the ajax request
								var _xhr = new XMLHttpRequest();
								_xhr.open('POST', this.options.delete, true);
								_xhr.responseType = 'json';
								_xhr.send(_delete_form);

								// Set the event handler
								_xhr.addEventListener('load', function(container, event){
									// Check the request
									var request = event.target;

									try{
										// Check the request
										if( request.status === 200 ){
											// Get the response
											var response = request.response;

											// Check the response
											if( response.error ){
												// There was an error deleting the media
												throw response.response;
											}
											else{
												// Find the input
												//var media = container.querySelector('input.uploader-value');
                                                //
                                                //console.log(media);

												// Remove the input
												//this.container.querySelector('input[]');

												// The image was deleted
												container.classList.remove('uploaded');
												container.classList.add('hide');

												// Set a timeout
												setTimeout(function(){
													// Add the deleted class
													container.classList.add('deleted');
												}.bind(container), 400);

												// Set a new timeout
												setTimeout(function(){
													// Remove the file
													container.parentElement.removeChild(container);
												}.bind(container), 850);
											}
										}
										else{
											// Could not send request
											throw 'Could not send the request';
										}
									}
									catch( error ){
										// Could not delete the file
										container.classList.remove('uploaded');
										container.classList.add('failed');

										// Update the status
										var status = container.querySelector('.file-status');
										status.classList.add('error');
										status.classList.remove('success');
										status.lastChild.nodeValue = error;
									}
								}.bind(this, container));
							}
						}.bind(this, container));
					}
				}
				else{
					// Could not send the request
					throw 'Could not send the request';
				}
			}
			catch( error ){
				// Could not upload the file
				// Display the error message
				//console.log(error);
				status.classList.add('error');
				status.lastChild.nodeValue = error;

				// Add the error class
				container.classList.add('failed');
			}
		}.bind(this, container));

		// Send the request
		xhr.send(form_data);

		// Create a file reader
		var reader = new FileReader();

		// Set the on load function
		reader.onload = function(e){
			// Get the preview and show the image
            var exif = EXIF.readFromBinaryFile(base64ToArrayBuffer(e.target.result));
			var preview                   = this.querySelector('.preview');
			preview.style.backgroundImage = 'url(' + e.target.result + ')';
            if (exif && exif.Orientation) {
                switch(exif.Orientation) {
                    case 1:
                        break;
                    case 2:
                        //flip image
                        $(preview).addClass("flip");
                        break;
                    case 3:
                        // rotate 180
                        $(preview).addClass("rotate180");
                        break;
                    case 4:
                        //rotate 180 and flip
                        $(preview).addClass("rotate180flip");
                        break;
                    case 5:
                        // rotate 90 and flip
                        $(preview).addClass("rotate90flip");
                        break;
                    case 6:
                        //rotate 90
                        $(preview).addClass("rotate90");
                        break;
                    case 7:
                        //rotate 270 and flip
                        $(preview).addClass("rotate270flip");
                        break;
                    case 8:
                        //rotate 270
                        $(preview).addClass("rotate270");
                        break;
                    default:
                        // idunno lmao
                        break;
                }
            }
            // Set a timeout to remove the spinner
			setTimeout(function(){
				// Remove the spinner
				this.classList.add('hide-spinner');
			}.bind(preview.parentNode), 250);
		}.bind(container);

		// Read the file
		reader.readAsDataURL(file);

        //fixExifOrientation(file);
	}

    function base64ToArrayBuffer(base64) {
        base64 = base64.replace(/^data\:([^\;]+)\;base64,/gmi, '');
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array( len );
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }

	_Uploader.prototype.addFile = function(file){
		// Create the file container
		var file_container = __create('div', {
			'class' : 'file uploading'
		});

		// Create the preview container
		var preview_container = __create('div', {
			'class' : 'preview-container'
		}, file_container);

		// Create the preview
		var preview = __create('div', {
			'class' : 'preview'
		}, preview_container);

		// Create the spinner container
		var spinner_container = __create('div', {
			'class' : 'spinner-container'
		}, preview_container);

		// Create the spinner
		var spinner = __create('div', {
			'class' : 'spinner small'
		}, spinner_container);

		// Create the info container
		var info_container = __create('div', {
			'class' : 'info-container'
		}, file_container);

		// Create the status container
		var status_container = __create('div', {
			'class' : 'status-container'
		}, info_container)

		// Create the text nodes for the label and the status
		var label_text  = document.createTextNode(file.name + ' (' + __niceFileSize(file.size) + ')');
		var status_text = document.createTextNode('Uploading...');

		// Create the labels
		var label  = __create('p', { class : 'file-name' }, status_container);
		var status = __create('p', { class : 'file-status' }, status_container);

		// Add the text nodes
		label.appendChild(label_text);
		status.appendChild(status_text);

		// Create the loading bar
		var loading_container = __create('div', { 'class' : 'loading-bar' }, info_container);
		var bar               = __create('div', { 'class' : 'bar' }, loading_container);

		// Create the actions container
		var actions_container = __create('div', { 'class' : 'actions-container' }, file_container);

		// Add the file to the list
		this.list.appendChild(file_container);

		// Return the file container
		return file_container;
	}

	function __create(element, attributes, parent){
		// Create the element
		var element = document.createElement(element);

		// Loop through the attributes
		for( var prop in attributes ){
			// Set the attribute
			element.setAttribute(prop, attributes[prop]);
		}

		// Check the parent
		if( (typeof parent) !== 'undefined' ){
			// Set the parent
			parent.appendChild(element);
		}

		// Return the element
		return element;
	}

	function __niceFileSize(size){
		// Check the file size
		if( size >= 1000000 ){
			// Return nice megabyte size
			return (size / 1000000).toFixed(1).toString() + ' MB';
		}
		else if( size >= 1000 ){
			// Return nice kilobyte size
			return (size / 1000).toFixed(1).toString() + ' kB';
		}
		else{
			// Return the bytes
			return size.toString() + ' bytes';
		}
	}

	/**
	 * Uploader constructor
	 * Wrapper for _Uploader
	 * @returns {Uploader}
	 * @constructor
	 */
	window.Uploader = function(options){
		// Create the default settings
		var defaults = {
			'target' : '',
			'name'   : 'uploader',
		};

		// Create the uploaders list
		this.uploaders = [];

		// Check the options
		if( options instanceof Element ){
			// Set the uploader
			this.uploaders.push(new _Uploader(options, defaults));
		}
		else{
			// Check the options
			if( options === undefined ){
				// Set the options to a blank object
				options = {};
			}
			else if( (typeof options) === 'string' ){
				// Set the default target
				defaults.target = options;

				// Set the options to a blank object
				options = {};
			}

			// Loop through the properties in the defaults
			for( var prop in defaults ){
				// Set the setting
				if( options.hasOwnProperty(prop) === false ){
					// Add the property to the options
					options[prop] = defaults[prop];
				}
			}

			// Check the target
			if( (typeof options['target']) !== 'string' ){
				// Invalid target
				throw 'Invalid target';
			}

			// Get the containers
			var containers = document.querySelectorAll(options['target']);

			// Loop through the containers
			for( var i = 0, length = containers.length; i < length; i++ ){
				// Create an uploader
				this.uploaders.push(new _Uploader(containers[i], options));
			}
		}

		// Return the object
		return this;
	}
}());