(function(){
    /**
     * Edit Profile Handler
     * Handles profile requests responses
     * @param response
     * @constructor
     */
    window.EditHandler = function(jquery, source, response){
        // Initialize the handler
        Handler.call(this, jquery, source, response);
    }

    // Set the inheritance
    window.EditHandler.prototype   = Object.create(window.Handler.prototype);
    window.EditHandler.constructor = EditHandler;

    /**
     * Initializes the handler
     * @param response The response from the handler
     */
    EditHandler.prototype.initialize = function(response){

        // Add the actions allowed
        this._allowed.push('delete');
        this._allowed.push('industry')
        this._allowed.push('specialization')
        this._allowed.push('panelController')
        this._allowed.push('addUser')
        this._allowed.push('edit3Panel')
        this._allowed.push('edit2Panel')
        // Prepare the response
        this.prepare(response);

        // Run the response
        this.run();
    }
    /**
     * Initializes the handler
     * @param response The response from the handler
     */
    EditHandler.prototype.industry = function(response) {

        // Add industry
        let industry_html = this._data.html,
            container = this._source.parents('ul').prev(),
            msg = this._data.message

        addNotification(msg)
        container.append(industry_html)

        if (container.prev()) {
          container.prev().hide()
        }

    }
    EditHandler.prototype.specialization = function(response) {

        // Add specialization
        let specialization_html = this._data.html,
            container = this._source.parents('ul').prev(),
            msg = this._data.message

        addNotification(msg)
        container.append(specialization_html)

        if (container.prev()) {
          container.prev().hide()
        }

    }
    EditHandler.prototype.edit3Panel = function(response) {
      let editPanel = this._source.parents('.item'),
          defaultPanel = editPanel.prev(),
          data = this._data.project? this._data.project : this._data.experience,
          company_data = data.company,
          company = defaultPanel.find(".company"),
          msg = this._data.message

      addNotification(msg)


      if (this._data.error == true) return

      let title_data = data.title,
          title = defaultPanel.find('.title'),

          industry_data = data.industry,
          specialization_data = data.specialization,
          in_sp = defaultPanel.find('.in-sp'),

          start_date = data.start_date,
          end_date = data.end_date,
          date = defaultPanel.find('.date'),

          bio_data = data.bio,
          bio = defaultPanel.find('.bio')


      company.html(company_data)
      title.html(title_data)
      in_sp.html(`${industry_data}, ${specialization_data}`)
      date.html(`${start_date} - ${end_date}`)
      bio.html(bio_data).text();

      swapToDefault.call(this._source);
    }
    EditHandler.prototype.edit2Panel = function(response) {
      let editPanel = this._source.parents('.item'),
          defaultPanel = editPanel.prev(),
          data = this._data.education? this._data.education : this._data.certification,
          company_data = data.company,
          company = defaultPanel.find(".company"),
          msg = this._data.message

      // console.log(this._data);
      addNotification(msg)
      if (this._data.error == true) return

      let title_data = data.title,
          title = defaultPanel.find('.title'),

          start_date = data.start_date,
          end_date = data.end_date,
          date = defaultPanel.find('.date'),

          bio_data = data.bio,
          bio = defaultPanel.find('.bio')

      company.html(company_data)
      title.html(title_data)
      date.html(`${start_date} - ${end_date}`)
      bio.html(bio_data).text()
    }
    EditHandler.prototype.panelController = function(response) {

        // Add specialization
        let panel_html = this._data.html,
            listCount = this._data.listCount,
            cardBody = this._source.parents('.card-body'),
            cardFooter = cardBody.next(),
            container = (listCount === 0 )? cardBody : cardBody.children('.extra-items'),
            msg = this._data.message,
            item = cardBody.children('.add-item-accordian')


        addNotification(msg)
        container.prepend(panel_html)
        cardBody.children('form').removeAttr('style')
        item.collapse('hide')

        if ( listCount > 0 ) {
          cardFooter.removeClass('hide')
        }
        $('.datepicker').pickadate({
          format: 'mmmm dd, yyyy',
            selectYears: 40,
        })
    }
    EditHandler.prototype.addUser = function(response) {

        // Add specialization
        let user_html = this._data.html,
            container = this._source.parents('.card-header').next().children('.flex'),
            msg = this._data.message

        addNotification(msg)

        if (container.length == 0) {
          container = this._source.parents('.members-form').prev()
        }
        container.append(user_html)
    }

    /**
     * next pull need to update firm members table
     * Deletes a row
     */
    EditHandler.prototype.delete = function() {
        // Find the row
        let list = this._source.parents('ul'),
            listCount = this._data.listCount  - 1, // NOTE: the minus 1 is for removing the addition count for handling delete functions hidding of footer
            cardBody = this._source.parents('.card-body'),
            cardFooter = cardBody.next(),
            item = this._source.parents('.item-container'),
            item_container = item.parent(),
            items = item_container.find('form'),
            isCardBody = item_container.hasClass('card-body'),
            notice = list.prev(),
            msg = this._data.message,
            nextItem = items[2]

        addNotification(msg)
        if (isCardBody && nextItem) {
          $(nextItem).clone().prependTo(item_container)
          nextItem.remove()
        }
        if (list.children().length === 0) {
          notice.show()
        }
        if (listCount === 1) {
          cardFooter.addClass('hide')
        }
        item.remove()
    }

}());
