let edit_profile = document.querySelector('.edit'),
    is_edit_profile = (edit_profile)? true : false


if (is_edit_profile) {
  let add_industry_btn = document.querySelector('#add-industry-btn'),
      add_specialization_btn = document.querySelector('#add-specialization-btn')


  if (add_industry_btn) {
    add_industry_btn.addEventListener('click', changeIndustryBtn)
    add_specialization_btn.addEventListener('click', changeSpecializationBtn)
  }


  $('#profile').on('click','#add-cover-photo-btn', swapToUpload)
  $('#profile').on('click','.edit', swapToEdit)
  // $('#profile').on('click','.edit-save-target', swapToDefault)
  $('#profile').on('click','.cancel', swapToDefault)
  $('#profile').on('click','.add-item', addItem)
  $('#profile').on('change','.custom-file #undefined-handle', showSelectedFile)
}

// NOTE: BTN Text Changing
function changeIndustryBtn(e) {
  e.target.text = (e.target.text == "Cancel")? "Add Industry" : "Cancel"
}
function changeSpecializationBtn(e) {
  e.target.text = (e.target.text == "Cancel")? "Add Specialization" : "Cancel"
}
function swapToUpload(e) {
  let span = $('#add-cover-photo-btn'),
      label = $('.custom-file-label'),
      text = span.html()

  if (text == "Remove") {
    span.html('Upload')
    label.html('Upload Cover Photo')
  }
}


// NOTE: FILE Upload
function showSelectedFile(e) {
  let file_uploader_label = $(this).parent().children('label'),
      file_name = $(this).val(),
      span = $('#add-cover-photo-btn'),
      text = span.html()

  span.html((text == "Remove")? "Upload" : "Remove")
  file_uploader_label.text(file_name)
}


// NOTE: for panels
function addItem(e) {
  let item = $(this).parents('.card-header').next().children('form')
  item.slideToggle()
}
function swapToEdit() {
  let parent = $(this).closest('.item'),
      neibour = parent.next()

  parent.addClass('hide')
  neibour.removeClass('hide')
}
function swapToDefault() {
  // e.preventDefault()

  let item = $(this).closest('.item'),
      neibour = item.prev()

  item.addClass('hide')
  neibour.removeClass('hide')
}
