jQuery(document).ready(function($) {

    // Advert audience toggle
    $("form input[name=audience]").on("change", function() {
        var toggleBox = $("div.audience-toggle");
        if($(this).val() == "targeted") {
            toggleBox.slideDown();
            // Add required to both the fields
            // toggleBox.find(".input-select").addClass("required");
        } else {
            toggleBox.slideUp();
            // Remove required from both fields
            // toggleBox.find(".input-select").removeClass("required");
        }
    });

    if( $("#publish-ad").length > 0 ) {

        // Update ad prices on publish page
        $("select#region").on("change", updatePrice);
        $("input[name='type']").on("change", updatePrice);

        /**
         * Update Prices
         */
        function updatePrice() {

            // Get the region
            var region = $("select#region");

            // Get checked adType
            var adType = $("input[name='type']:checked");

            // Make sure both region and type are selected
            if( region.val() != '' && adType.val() != '' ) {

                // Build data
                var data = {
                    _ajax   : true,
                    region  : region.val(),
                    ad_type : adType.val()
                };

                // Send the ajax request
                $.ajax({
                    url         : this.dataset.action,
                    data        : data,
                    method      : 'POST',
                    dataType    : 'json',
                    success     : function(response){

                        // Check the response
                        if( response.error ){
                            // Add a notification with the response
                            addNotification(response.response, 'error', 3000);
                        } else{

                            // Get the response
                            response = response.response;

                            // Get the data
                            var html = response.html;

                            // Get the container
                            var container = $('#price-info');

                            // Add
                            container.html(html);

                            // Show message if any
                            if(response.hasOwnProperty('message')) {
                                addNotification(response.message, 'success');
                            }

                        }

                    }.bind(this),
                    error: function(xhr){
                        // Show a notification
                        addNotification('There was an error sending the request', 'error');
                    }
                });

            }
            // Prevent the default
            event.preventDefault();
        }

    }
});
