//jQuery(document).ready(function($){
(function(){
	/**
	 * Base handler
	 * Handles json responses
	 * @param jquery The query handle
	 * @param source The source which called the handler
	 * @param response The response to handle
	 * @constructor
	 */
	window.Handler = function(jquery, source, response){
		// Initialize the handler
		this._jquery  = jquery;
		this._source  = jquery(source);
		this._root    = this._source;
		this._current = null;
		this._prev    = true;
		this._data    = {};

		// The actions allowed
		this._allowed = [
			'root',
			'parent',
			'find',
			'hide',
			'redirect',
			'prepend',
			'append',
            'empty',
            'show'
		];

		// Call initialize
		this.initialize(response);
	}

	/**
	 * Initializes the handler
	 * @param response The response from the request
	 */
	Handler.prototype.initialize = function(response){
		// Prepare the handler
		this.prepare(response);

		// Run the handler
		this.run();
	}

	/**
	 * Fins an element through the root
	 * Setst the root of if the parnet is found
	 * @returns {boolean}
	 */
	Handler.prototype.parent = function(){
		// Get the parent
		var parent = this._source.closest(this._current.action);

		// Check the parent
		if( parent.length > 0 ){
			// Set the root
			this._root = parent;

			// Action runned
			return true;
		}

		// Not found
		return false;
	}

	/**
	 * Finds an element through the root
	 * Sets the root if the element is found
	 * @returns {boolean}
	 */
	Handler.prototype.find = function(){
		// Find the element
		var element = this._root.find(this._current.action);

		// Check the element
		if( element.length > 0 ){
			// Set the root
			this._root = element;

			// Element was found
			return true;
		}

		// Element was not found
		return false;
	}

	/**
	 * Sets the root of the handler
	 */
	Handler.prototype.root = function(){
		// Check the root
		if( typeof(this._current.action) === 'string' ){
			// Find the parent
			this._root = this._jquery(this._current.action);
		}
	}

	/**
	 * Hides an element
	 * Adds the hide class to the target
	 * Waits 450ms, then slides up
	 */
	Handler.prototype.hide = function(){
		// The target to hide
		var target = null;

		// Check if the handler has a root
		if( this._root && this._root.find(this._current.action).length > 0 ){
			// Find the target
			target = this._root.find(this._current.action);
		}
		else{
			// Find the target normally
			target = this._jquery(this._current.action);
		}

		// Check the length
		if( target.length > 0 ){
			// Add the hide class to the target
			target.addClass('hide');

			// Create a timeout
			setTimeout(function(){
				// Slide up the target
				this.slideUp(450);
			}.bind(target), 450);

			// Action ran
			return true;
		}

		// Did not find the target
		return false;
	}

    /**
     * Shows an element
     */
    Handler.prototype.show = function(){
        // The target to show
        var target = null;

        // Check if the handler has a root
        if( this._root && this._root.find(this._current.action).length > 0 ){
            // Find the target
            target = this._root.find(this._current.action);
        }
        else{
            // Find the target normally
            target = this._jquery(this._current.action);
        }

        // Check the length
        if( target.length > 0 ){
            // Remove class to the target
            target.removeClass('hide');

            // Create a timeout
            setTimeout(function(){
                // show the target
                this.slideDown(300);
            }.bind(target), 300);

            // Action ran
            return true;
        }

        // Did not find the target
        return false;
    }

    Handler.prototype.empty = function() {
        if( this._current.action ) {
            this._root.empty();
        }
    }

	/**
	 * Redirects
	 */
	Handler.prototype.redirect = function(){
		// Redirect
		window.location.href = this._current.action;
	}

	/**
	 * Parses an item into HTML
	 * Should return a jQuery array
	 * @param item the text to parse
	 * @returns {*} The parsed item
	 */
	Handler.prototype.parse = function(item){
		// Return the parsed item
		return this._jquery(this._jquery.parseHTML(item));
	}

	/**
	 * Prepends to a container
	 */
	Handler.prototype.prepend = function(){
		// Get the type of action
		var type = typeof(this._current.action);

		// The items added
		var items = [];

		// Check the type of the action
		if( type === 'object' ){
			// Loop through the items in the object
			for( var key in this._current.action ){
				// Set the container
				var container = this._root.find(key);

				// Check the container
				if( container.length > 0 ){
					// Reset the container to root
					container = this._root;
				}

				// Create the item
				var item = '';

				// Check the item
				if( this._data.hasOwnProperty(this._current.action[key]) ){
					// Add the item in the data
					item = '<div class="animation-wrapper">' + this._data[this._current.action[key]] + '</div>';
				}
				else{
					// Add the item item
					item = '<div class="animation-wrapper">' + this._current.action + '</div>';
				}

				// Parse the item
				item = this.parse(item);

				// Hide the item
				item.addClass('hide');

				// Set a timeout to slide down the item
				setTimeout(function(item){
					// Slide down the item
					item.slideDown(500, function(item){
						this._jquery(item).removeClass('hide');
					}.bind(this, item));
				}.bind(this, item), 300);

				// Prepend the item
				this._root.prepend(item);

				// Add the item to the list
				items.push(item);
			}
		}
		else if( type === 'string' ){
			// Create the item
			var item = '';

			// Check if the type is on the data array
			if( this._data.hasOwnProperty(this._current.action) ){
				// Add the data
				item = '<div class="animation-wrapper">' + this._data[this._current.action] + '</div>';
			}
			else{
				// Append to the root
				item = '<div class="animation-wrapper">' + this._current.action + '</div>';
			}

			// Parse the item
			item = this.parse(item);

			// Hide the item
			item.addClass('hide');

			// Set a timeout to slide down the item
			setTimeout(function(item){
				// Slide down the item
				item.slideDown(500, function(item){
					this._jquery(item).removeClass('hide');
				}.bind(this, item));
			}.bind(this, item), 300);

			// Prepend the item
			this._root.prepend(item);

			// Append the item
			items.push(item);
		}

		// Run the after prepend method
		this.afterPrepend(items);

		// The action ran
		return true;
	}

	/**
	 * Append method
	 * Appends a list of items to the root
	 * @returns {boolean}
	 */
	Handler.prototype.append = function(){
		// Get the action type
		var type = typeof(this._current.action);

		// The items added
		var items = [];

		// Check the type of action
		if( type === 'object' ){
			// Loop through the items in the object
			for( var key in this._current.action ){
				// Find the container
				var container = this._root.find(key);

				// Check the container
				if( container.length === 0 ){
					// Set the root as the container
					container = this._root;
				}

				// Check the type of the action
				if( typeof(this._current.action[key]) === 'object' ){
					// TODO:
				}
				else{
					// Assume the action is a string
					// The item
					var item = '';

					// Check if the type is on the data array
					if( this._data.hasOwnProperty(this._current.action[key]) ){
						// Add the data
						item = '<div class="animation-wrapper">' + this._data[this._current.action[key]] + '</div>';
					}
					else{
						// Append to the root
						item = '<div class="animation-wrapper">' + this._current.action + '</div>';
					}

					// Parse the item
					item = this.parse(item);

					// Hide the item
					item.addClass('hide');

					// Set a timeout to slide down the item
					setTimeout(function(item){
						// Slide down the item
						item.slideDown(500, function(item){
							this._jquery(item).removeClass('hide');
						}.bind(this, item));
					}.bind(this, item), 300);

					// Prepend the item
					this._root.append(item);

					// Append the item
					items.push(item);
				}
			}
		}
		else if( type === 'string' ){
			// The item
			var item = '';

			// Check if the type is on the data array
			if( this._data.hasOwnProperty(this._current.action) ){
                // For table structures
                if( this._current.action === "table" ) {
                    item = this._data[this._current.action];
                } else {
                    // Add the data
                    item = '<div class="animation-wrapper">' + this._data[this._current.action] + '</div>';
                }
			}
			else{
				// Append to the root
				item = '<div class="animation-wrapper">' + this._current.action + '</div>';
			}

			// Parse the item
			item = this.parse(item);

			// Hide the item
			item.addClass('hide');

			// Set a timeout to slide down the item
			setTimeout(function(item){
				// Slide down the item
				item.slideDown(500, function(item){
					this._jquery(item).removeClass('hide');
				}.bind(this, item));
			}.bind(this, item), 300);

			// Prepend the item
			this._root.append(item);

			// Append the item
			items.push(item);
		}

		// Call after append
		this.afterAppend(items);

		// Action ran
		return true;
	}

	/**
	 * Called after an append has been completed
	 * @param items
	 */
	Handler.prototype.afterPrepend = function(items){
		// Virtual method
	}

	/**
	 * Called after an appen has been complted
	 * @param items The items appended
	 */
	Handler.prototype.afterAppend = function(items){
		// Virtual method
	}

	/**
	 * Prepare the handlers
	 * Extracts the data from the response and creates the actions
	 * @param response The response given from the server
	 */
	Handler.prototype.prepare = function(response){
		// The first and current action
		var first   = null;
		var current = null;

		// Loop through the response
		for( var action in response ){
			// Check the action
			if( this._allowed.indexOf(action) > -1 ){
				// Create a new action
				var action = new Action(action, response[action]);

				// Check the current
				if( current ){
					// Set the next action
					current.next = action;

					// Set the new current
					current = action;
				}
				else{
					// No current action
					// Set the first action
					first   = action;
					current = action;
				}
			}
			else{
				// Add the data
				this._data[action] = response[action];
			}
		}

		// Set the current action
		this._current = first;
	}

	/**
	 * Runs the actions on the handler
	 */
	Handler.prototype.run = function(){
		// Check the current
		if( this._current ){
			// Check if the current action has a wait
			if( this._prev && this._current.options.hasOwnProperty('wait') && this._current.options['wait'] > 0 ){
				// Get the wait
				var wait = this._current.options['wait'];

				// Remove the wait
				this._current.options['wait'] = 0;

				// Set the timeout
				setTimeout(this.run.bind(this), wait);
			}
			else{
				// Check if the action is found
				if( typeof(this[this._current.type]) === 'function' ){
					// Run the action
					this._prev = this[this._current.type]();
				}
				else{
					// Action not found
					//console.log('Action: `' + this._current.type + '` was not found');
				}

				// Set the next action
				this._current = this._current.next;

				// Keep running
				this.run();
			}
		}
	}
}());